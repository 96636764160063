<template>
  <div class="content-wrapper overflow-visible">
    <div class="page-header page-header-sticky page-header-sticky-open">
      <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">

        <div class="page-header-info flex-1 py-3 text-uppercase">
          <div class="d-flex justify-content-between">
            <div>
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                  <span>{{row.ap_fullname||"-"}}</span>
                  <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                    title="No Rekam Medis">{{row.ap_code||"-"}}</span>
                  <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                    title="No Registrasi">{{rowReg.aur_reg_code||"-"}}</span>
                  <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No KTP">{{row.ap_nik||"-"}}</span>
                  <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                    title="No SEP">{{rowReg.absd_no_sep||"-"}}</span>
                </h5>
              </div>
              <div class="phi-sub-info d-flex">
                <div class="phi-info d-flex flex-column">
                  <small>Tanggal Lahir</small>
                  <strong class="font-weight-semibold">{{row.ap_dob | moment("DD MMM YYYY")}}</strong>
                </div>
                <div class="phi-info d-flex flex-column pl-2 ml-3">
                  <small>Usia</small>
                  <strong
                    class="font-weight-semibold">{{row.ap_usia_with_ket || "-"}}<br />({{row.ap_gol_usia||"-"}})</strong>
                </div>
                <div class="phi-info d-flex flex-column pl-2 ml-3">
                  <small>Jenis Kelamin</small>
                  <strong class="font-weight-semibold">{{row.cg_label}}</strong>
                </div>
                <div class="phi-info d-flex flex-column pl-2 ml-3">
                  <small>Opsi Pembayaran</small>
                  <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                </div>
                <div class="phi-info d-flex flex-column pl-2 ml-3">
                  <small>Agama</small>
                  <strong class="font-weight-semibold">{{row.ap_agama_text || "-"}}</strong>
                </div>
              </div>
            </div>

            <div class="phi-info d-flex flex-column col-md-3 ml-auto">
              <small>Dokter</small>
              <h5 class="phi-main font-weight-semibold mb-0">
                <span>{{rowReg.bu_full_name||"-"}}</span>
              </h5>
              <div class="mb-2">
                <strong class="font-weight-semibold">{{rowReg.mpo_name||"-"}}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
        <i class="icon-arrow-up5 align-middle"></i>
        <span class="align-middle">Sembunyikan Informasi Pasien</span>
      </a>
    </div>
    <div class="content">
      <div class="card">
        <div class="card-header">
          <div class="d-flex align-items-center">
            <h5 class="card-title font-weight-semibold mr-auto">Rekam Medis No. <span
                class="text-blue-600">{{row.ap_code||"-"}}</span></h5>
          </div>
        </div>
        <table class="table table-bordered table-striped table-hover table-sm patient-table">
          <thead>
            <tr>
              <th>No. Reg</th>
              <th>Tanggal Reg</th>
              <th>Nama Dokter</th>
              <th>Keluhan</th>
              <th>Kajian</th>
              <th>Riwayat RM</th>
              <th>Catatan Asuhan</th>
              <th v-if="uRadiologi == user.levelId">Dokumen Radiologi</th>
              <th>Dokumen</th>

            </tr>
          </thead>
          <template v-if="(dataUGDList||[]).length">
            <template v-for="(v,k) in (dataUGDList||[])">
              <tbody :class="v.isHead? 'isHead': ''" :key="k">
                <tr :class="v.aurm_is_draft == 'Y' ? 'table-warning' : ''">
                  <td>
                    <template
                      v-if="(v.aurm_can_edit_by && v.aurm_is_approve == 'Y' || otherConditionalUGD(v) ) || isDev">
                      <a v-b-tooltip.hover
                        v-if="!(v.aurm_is_draft == 'Y' && v.aur_is_menolak_radiologi == 'Y' && user.levelId == uRadiologi)"
                        href="javascript:;" @click="redirectUGD(v)"
                        class="text-primary font-weight-semibold border-bottom">{{v.aur_reg_code||"-"}}</a>
                      <a v-b-tooltip.hover v-else href="javascript:;"
                        class="text-primary font-weight-semibold border-bottom">{{v.aur_reg_code||"-"}}</a>
                    </template>
                    <template v-else>
                      <span>{{v.aur_reg_code||"-"}}</span>
                    </template>


                    <template v-if="v.aurm_is_draft == 'Y'">

                      <span
                        v-if="v.aurm_is_draft == 'Y' && v.aur_is_menolak_radiologi == 'Y' && user.levelId == uRadiologi"
                        :class="`badge bg-warning ml-1`">
                        Pasien Menolak Tindakan
                      </span>
                      <span v-else :class="`badge bg-warning ml-1`">
                        Draft
                      </span>
                      <br />
                      <small>{{v.petugas||"-"}}</small>
                    </template>


                  </td>
                  <td>
                    <span v-if="v.aur_reg_date">{{ v.aur_reg_date | moment("DD MMM YYYY")}}</span>
                    <span v-else>{{ v.aur_reg_date | moment("DD MMM YYYY")}}</span>
                  </td>
                  <td>
                    <strong>{{v.bu_full_name||"-"}}<small><br>{{v.mpo_name}}</small></strong>
                  </td>

                  <td>
                    <span>{{v.keluhan||"-"}}</span>
                  </td>


                  <td>

                    <a href="javascript:;" v-if="v.aur_is_secondary_assesment_done == 'Y'"
                      @click="toDetailKajianPerawatUGD(v,v.aurm_ar_id)" class="btn btn-sm btn-icon"
                      :class="'alpha-blue border-blue'" v-b-tooltip.hover="'Lihat Kajian Perawat'"><i
                        class="icon-file-eye"></i></a>
                    <span v-else>-</span>

                  </td>

                  <td>
                    <a href="javascript:;" v-if="v.aur_is_primary_assesment_done == 'Y'" @click="doOpenRiwayatRMUGD(v)"
                      v-b-tooltip.hover="'Lihat Riwayat Rekam Medis'"
                      class="btn btn-sm btn-icon alpha-orange border-orange"><i class="icon-file-eye"></i></a>
                    <span v-else>-</span>

                  </td>

                  <td>
                    <template>
                      <a href="javascript:;" v-if="uPerawatUGD == user.levelId" @click="toCatatanAsuhan(v.aur_id)"
                        class="btn btn-icon bg-teal mr-2" v-b-tooltip.hover
                        :title="v.aur_have_catatan_asuhan !='Y' ? 'Tambah Catatan Asuhan' : 'Edit Catatan Asuhan'">
                        <i class="icon-file-plus"></i>
                      </a>

                      <a href="javascript:;" @click="toCatatanAsuhanDetail(v.aur_id)"
                        class="btn btn-icon alpha-teal border-teal text-teal-800" data-popup="tooltip" v-b-tooltip.hover
                        title="Lihat Catatan Asuhan">
                        <i class="icon-file-eye"></i>
                      </a>
                    </template>
                  </td>

                  <td v-if="uRadiologi == user.levelId">
                    <a v-if="v.aur_is_menolak_radiologi != 'Y'" href="javascript:;" @click="lihatDokumen(v)"
                      v-b-tooltip.hover="'Lihat Dokumen'" class="btn btn-sm btn-icon alpha-danger border-danger"><i
                        class="icon-file-eye"></i></a>
                    <span v-else> - </span>
                  </td>

                  <td>
                    <router-link
                      :to="{name: 'UGDTindakLanjut', params: {pageSlug: v.aurm_aur_id}, query: {isDetailKeluarUGD : true}}"
                      v-b-tooltip.hover class="btn btn-icon rounded-round btn-sm alpha-success border-success"
                      title="Lihat Dokumen Tindak Lanjut"><i class="icon-eye"></i></router-link>
                  </td>


                </tr>
              </tbody>
            </template>
          </template>
          <tbody v-else-if="!(dataUGDList||[]).length && dataUGDList">
            <tr>
              <th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
                Data Tidak Ditemukan
              </th>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <th colspan="99" class="table-info text-blue-700 text-uppercase font-weight-semibold">
                <div class="skeletal-comp mb-2"></div>
                <div class="skeletal-comp mb-2"></div>
                <div class="skeletal-comp"></div>
              </th>
            </tr>
          </tbody>
          <tfoot v-if="(dataUGDList||[]).length && pageNow < dataUGD.last_page">
            <tr>
              <td class="text-center" colspan="99">
                <a href="javascript:;" @click="loadMoreUGD"
                  class="btn btn-outline-primary btn-sm font-weight-semibold">Tampilkan Rekam Medis Lainnya</a>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div class="card-footer">
        <div class="text-right">
          <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
        </div>
      </div>
      <!-- /dashboard content -->

      <b-modal v-model="openDetailKajian"
        :title="row.arm_is_kajian_awal == 'Y' ? 'Detail Kajian Awal': 'Detail Kajian Lanjutan'" size="xl" hide-footer>
        <DetailKajianAwal v-bind="passToSub" v-if="row.arm_is_kajian_awal == 'Y'" />
        <DetailKajianLanjutan v-bind="passToSubLanjutan" v-else />
      </b-modal>


      <b-modal v-model="openRwiayatRM" :title="'Detail Rekam Medis'" size="xl" hide-footer>
        <RiwayatRM v-bind="passToSubRM" />
      </b-modal>

      <b-modal v-model="openDetailKajianPerawatUGD" title="Kajian Sekunder" size="xl" hide-footer>
        <KajianPerawatUGD v-bind="passToSubPerawatUGD" />
      </b-modal>

      <b-modal v-model="openRiwayatRMUGD" title="Detail Riwayat Rekam Medis" size="xl" hide-footer>
        <RiwayatRMUGD v-bind="passToSubRMUGD" />
        <div class="row mt-3">
          <div class="col-md-12">
            <a href="javascript:;" @click="doOpenRiwayatRMUGD(openRiwayatData)"
              class="btn float-right btn-primary btn-labeled btn-labeled-left mb-1">
              <b><i class="icon-spinner11"></i></b>
              Muat Ulang
            </a>
          </div>
        </div>
      </b-modal>


      <b-modal v-model="openReport" :title="'Unduh Dokumen'" size="lg" hide-footer>
        <div class="row row-gutter">
          <div class="col-md-4">
            <div class="wrap_line mb-0">
              <h3 class="pb-1">Surat Keterangan Sakit</h3>
              <a href="javascript:;" @click="downloadReport('suratKeteranganSakit','Surat Keterangan Sakit')"
                class="btn btn-success btn-labeled btn-labeled-left">
                <b><i class="icon-download"></i></b>
                <span>Unduh</span>
              </a>
            </div>
          </div>

          <div class="col-md-4">
            <div class="wrap_line mb-0">
              <h3 class="pb-1">Surat Keterangan Sehat</h3>
              <a href="javascript:;" @click="downloadReport('suratKeteranganSehat','Surat Keterangan Sehat')"
                class="btn btn-success btn-labeled btn-labeled-left">
                <b><i class="icon-download"></i></b>
                <span>Unduh</span>
              </a>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal v-model="openCatatanAsuhan" title="Catatan Asuhan" size="xl" ok-only ok-title="Tutup">
        <!--
                <div class="row">
                    <div class="col-md-12">
                        <a href="javascript:;" @click="toCatatanAsuhan(regID)"
                        class="btn float-right btn-primary btn-labeled btn-labeled-left mb-1">
                        <b><i class="icon-pencil7"></i></b>
                        Edit Data
                        </a>
                    </div>
                </div>
                -->

        <div class="table-responsive">
          <table class="table table-bordered table-sm table-align-top">
            <thead>
              <tr>
                <td colspan="6" class="bg-info">
                  <span class="font-weight-semibold">Catatan Asuhan Perawat</span>
                </td>
              </tr>
              <tr>
                <th>Waktu</th>
                <th>Asesmen/Tindakan</th>
                <th>Waktu Evaluasi</th>
                <th>Evaluasi</th>
                <th>PPA</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="Object.keys(dataCatatanAsuhan||{}).length ">
                <template v-if="dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp == 'Y'">
                  <tr class="dpjpEl">
                    <td>
                      <span>{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_tanggal|moment("DD MMMM YYYY")}}
                        {{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_waktu||"-"}}</span>
                    </td>

                    <td>
                      <div class="tb-content">
                        <p class="font-weight-semibold text-blue-800">Melaporkan kondisi pasien ke DPJP</p>
                        <p><span class="font-weight-semibold">S</span>: Pasien datang ke UGD dengan keluhan utama
                          {{dataCatatanAsuhan.rowDataDokter.ausd_keluhan||"-"}} <span
                            v-if="dataCatatanAsuhan.rowDataDokter.ausd_riwayat">, Pasien mempunyai riwayat penyakit
                            dahulu: {{dataCatatanAsuhan.rowDataDokter.ausd_riwayat||"-"}} </span></p>
                        <p><span class="font-weight-semibold">B</span>: Riwayat Alergi:
                          <ul class="mb-0" v-if="dataCatatanAsuhan.rowDataDokter.ausd_has_alergi == 'Y'">
                            <li v-for="(v,k) in (dataCatatanAsuhan.rowDataDokter.ausd_alergi||[])" :key="k"><span
                                class="font-weight-semibold">({{getConfigDynamic(Config.mr.jenisAlergi,v.jenis)}})</span>{{v.name||"-"}}
                            </li>
                          </ul>
                          <span v-else> - </span>
                        </p>
                        <!--
                        <div class="mb-2">
                          <span class="font-weight-semibold">Keluhan Saat Ini</span>
                          <div>{{dataCatatanAsuhan.rowDataDokter.ausd_catatan_dokter||"-"}}</div>
                        </div>
                        -->
                        <div class="mb-2">
                          <span class="font-weight-semibold">Pengobatan yang dilakukan di UGD</span>
                          <div>{{dataCatatanAsuhan.rowDataDokter.aupd_rencana_pengobatan||"-"}}</div>
                        </div>
                        <div class="mb-2">
                          <span class="font-weight-semibold">Riwayat Pembedahan</span>
                          <div>
                            <span>{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_riwayat_pembedahan||"-"}}</span>
                          </div>
                        </div>
                        <div class="mb-2">
                          <span class="font-weight-semibold">Tindakan Invasif</span>
                          <template
                            v-if="(dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_tindakan_invasif||[]).length">
                            <ol class="mb-0"
                              v-for="(v,k) in (dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_tindakan_invasif||[])"
                              :key="k">
                              <li>{{v}}</li>
                            </ol>
                          </template>
                          <p v-else>Tidak ada Tindakan</p>
                        </div>
                        <div class="mb-2">
                          <span class="font-weight-semibold">A: </span>Keluhan saat ini
                          <div><span>{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_keluhan||"-"}}</span></div>
                        </div>
                        <div class="mb-2">
                          <span class="font-weight-semibold">Hasil Skor Nyeri</span>
                          <div>{{dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value}}
                            <template
                              v-if="(dataCatatanAsuhan.row.ap_usia > 6 || (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null && dataCatatanAsuhan.rowDataDokter.auod_nyeri_type == 'NP'))">
                              <span class="border-left ml-2 pl-2 font-weight-semibold"
                                v-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value == 0">Tidak Ada
                                Nyeri</span>
                              <span class="border-left ml-2 pl-2 font-weight-semibold"
                                v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 1 && dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value <= 3">Sedikit
                                Nyeri</span>
                              <span class="border-left ml-2 pl-2 font-weight-semibold"
                                v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 4 && dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value <= 5">Agak
                                Mengganggu</span>
                              <span class="border-left ml-2 pl-2 font-weight-semibold"
                                v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 6 && dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value <= 7">Mengganggu
                                Aktivitas</span>
                              <span class="border-left ml-2 pl-2 font-weight-semibold"
                                v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 8 && dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value <= 9">Sangat
                                Mengganggu</span>
                              <span class="border-left ml-2 pl-2 font-weight-semibold"
                                v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 10">Tak
                                Tertahankan</span>
                            </template>

                            <template
                              v-if="(dataCatatanAsuhan.row.ap_usia <= 6) || (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null)">
                              <template
                                v-if="(dataCatatanAsuhan.row.ap_usia > 1 || (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null && dataCatatanAsuhan.rowDataDokter.auod_nyeri_type == 'FL'))">
                                <span v-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value == 0"
                                  class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                                <span
                                  v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 1 && dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value < 4"
                                  class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan ringan</span>
                                <span
                                  v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 4 && dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value < 7"
                                  class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri
                                  Parah</span>
                              </template>

                              <template
                                v-if="((dataCatatanAsuhan.row.ap_usia <= 1  && dataCatatanAsuhan.row.ap_usia != null)|| (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null && dataCatatanAsuhan.rowDataDokter.auod_nyeri_type == 'NIPS')) ">
                                <span v-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value < 3"
                                  class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Ringan, Tidak Nyeri</span>
                                <span
                                  v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 3 && dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value < 5"
                                  class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang - Nyeri Ringan
                                  (intervensi tanpa obat, dievaluasi selama 30 menit)</span>
                                <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Hebat (intervensi
                                  tanpa obat, bila masih nyeri diberikan analgesik dan dievaluasi selama 30
                                  menit)</span>
                              </template>
                            </template>
                          </div>
                        </div>
                        <div class="mb-2">
                          <span class="font-weight-semibold">Tanda-tanda Vital</span>
                          <ul class="mb-0">

                            <li>Tekanan Darah:
                              {{dataCatatanAsuhan.rowDataDokter.auod_ttv_tekanan_darah_min||"-"}}/{{dataCatatanAsuhan.rowDataDokter.auod_ttv_tekanan_darah_max||"-"}}
                              mmHG</li>
                            <li>Nadi: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_nadi||"-"}} x/mnt -
                              {{dataCatatanAsuhan.rowDataDokter.auod_ttv_label||"-"}}</li>
                            <li>Gula Darah: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_gula_darah||"-"}} mg/dL</li>
                            <li>Pernafasan: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_pernafasan||"-"}} x/mnt</li>
                            <li>SPO2: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_spo2||"-"}}%</li>
                            <li>Suhu: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_suhu||"-"}}C</li>
                            <li>Berat Badan {{dataCatatanAsuhan.rowDataDokter.auod_ttv_weight||"-"}} kg</li>
                            <li>Tinggi: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_height||"-"}} cm</li>
                            <li>Lingkar Kepala: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_lingkar_kepala||"-"}} cm</li>
                            <li>Luas Permukaan Tubuh Anak:
                              {{dataCatatanAsuhan.rowDataDokter.auod_ttv_luas_permukaan_anak||"-"}} M<sup>2</sup></li>
                            <li>Kesadaran: <span>{{dataCatatanAsuhan.rowDataDokter.auod_gcs_total}} |

                                <span v-if="dataCatatanAsuhan.rowDataDokter.auod_gcs_total >= 14"> Composmentis</span>
                                <span
                                  v-else-if="dataCatatanAsuhan.rowDataDokter.auod_gcs_total >= 12 && dataCatatanAsuhan.rowDataDokter.auod_gcs_total <= 13">Apatis</span>
                                <span
                                  v-else-if="dataCatatanAsuhan.rowDataDokter.auod_gcs_total >= 10 && dataCatatanAsuhan.rowDataDokter.auod_gcs_total <= 11">Delirium</span>
                                <span
                                  v-else-if="dataCatatanAsuhan.rowDataDokter.auod_gcs_total >= 7 && dataCatatanAsuhan.rowDataDokter.auod_gcs_total <= 9">Somonolen</span>
                                <span
                                  v-else-if="dataCatatanAsuhan.rowDataDokter.auod_gcs_total >= 5 && dataCatatanAsuhan.rowDataDokter.auod_gcs_total <= 6">Sopor</span>
                                <span
                                  v-else-if="dataCatatanAsuhan.rowDataDokter.auod_gcs_total >= 4 && dataCatatanAsuhan.rowDataDokter.auod_gcs_total <= 4">Semi-Comma</span>
                                <span v-else>Coma</span>

                                <span class="ml-2" style="margin-right:20px;">E:
                                  {{dataCatatanAsuhan.rowDataDokter.auod_response_mata||0}}
                                </span>
                                <span style="margin-right:20px;">M:
                                  {{dataCatatanAsuhan.rowDataDokter.auod_response_motorik||0}}
                                </span>
                                <span style="margin-right:20px;">V:
                                  {{dataCatatanAsuhan.rowDataDokter.auod_response_verbal||0}}
                                </span>
                              </span></li>

                          </ul>
                        </div>

                        <div class="mb-2">
                          <span class="font-weight-semibold">Pemeriksaan Fisik</span>
                          <ul class="mb-0">
                            <template v-for="(v,k) in (Config.mr.configVitalSignUGD||[])">
                              <div :key="k" v-if="v.value != 'auod_vs_saraf'">
                                <li v-if="dataCatatanAsuhan.rowDataDokter[v.value] != 'TP'">
                                  {{v.label}} : <span>{{dataCatatanAsuhan.rowDataDokter[v.value+'_text']}}</span>
                                </li>
                              </div>
                              <div :key="k" v-else>
                                <template v-if="dataCatatanAsuhan.rowDataDokter[v.value] != 'TP'">
                                  <li>
                                    <span> {{v.label}} :</span>
                                  </li>
                                  <p class="mb-0">Refleks Fisiologis :
                                    {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_r_fisiologis||"-"}}</p>
                                  <p class="mb-0">Refleks Patologis :
                                    {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_r_patologis||"-"}}</p>
                                  <p class="mb-0">Babinski :
                                    {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_babinski||"-"}}</p>
                                  <p class="mb-0">Lasegue :
                                    {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_lasegue||"-"}}</p>
                                  <p class="mb-0">Bregard :
                                    {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_bregard||"-"}}</p>
                                  <p class="mb-0">Sicard : {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_sicard||"-"}}
                                  </p>
                                  <p class="mb-0">Kaku Duduk :
                                    {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_kaku_duduk||"-"}}</p>
                                  <p class="mb-0">Kernig Sign :
                                    {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_kernig_sign||"-"}}</p>
                                  <p class="mb-0">Brudzinski 1:
                                    {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_brudzinski||"-"}}</p>
                                  <p class="mb-0">Brudzinski 2:
                                    {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_brudzinski2||"-"}}</p>
                                </template>
                              </div>
                            </template>

                          </ul>
                          <span v-if="!isHavePemeriksaanFisik">Hasil pemeriksaan fisik normal</span>
                        </div>

                        <div class="mb-2">
                          <span class="font-weight-semibold">Tindakan Lab</span>
                          <p>{{selectedLabInput||"-"}}</p>
                        </div>
                        <div class="mb-2">
                          <span class="font-weight-semibold">Tindakan Radiologi</span>
                          <p>{{selectedRadioInput||"-"}}</p>
                        </div>
                        <div class="mb-2">
                          <span class="font-weight-semibold">R</span>
                          <div class="mb-1">
                            <span>Rekomendasi Intervensi yang Perlu Dilanjutkan</span>
                            <template
                              v-if="(dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_rekomendasi_intervensi||[]).length">
                              <ol class="mb-0"
                                v-for="(v,k) in (dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_rekomendasi_intervensi||[])"
                                :key="k">
                                <li>{{v}}</li>
                              </ol>
                            </template>
                            <span v-else>Tidak ada Tindakan</span>


                          </div>
                          <div class="mb-1">
                            <span>Usulan kolaborasi</span>
                            <template
                              v-if="(dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_usulan_kolaborasi||[]).length">
                              <ol class="mb-0"
                                v-for="(v,k) in (dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_usulan_kolaborasi||[])"
                                :key="k">
                                <li>{{v}}</li>
                              </ol>
                            </template>
                            <span v-else>Tidak ada Tindakan</span>

                          </div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <span>{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_tanggal_evaluasi}}
                        {{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_waktu_evaluasi}}</span>
                    </td>

                    <td>
                      <div class="form-group"
                        v-for="(vU,kU) in (dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_evaluasi_data||[])"
                        :key="kU">
                        <label for=""><b>Evaluasi #{{kU+1}}</b></label>
                        <pre
                          class="pre-input mb-1">{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_evaluasi_data[kU]['value']||"-"}}</pre>
                        <span
                          v-if="dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_evaluasi_data[kU]['is_done'] == 'Y'">
                          Selesai pada
                          {{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_evaluasi_data[kU]['done_date']}}</span>

                      </div>
                      <div id="verifikasiRanap">
                        <p class="font-weight-semibold text-blue-800">Data sudah diverifikasi oleh:</p>

                        <div class="form-group">
                          <label for="">Nama DPJP</label>
                          <p>{{dataCatatanAsuhan.rowDataDokter.nama_dokter_ranap||"-"}}
                          </p>
                        </div>

                        <div class="form-group">
                          <label>Tanggal</label>
                          <p>{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_evaluasi_date||"-"}}</p>
                        </div>

                        <div class="form-group">
                          <label>Jam</label>
                          <p>{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_evaluasi_waktu||"-"}}</p>
                        </div>
                      </div>
                    </td>

                    <td>
                      <span>{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_ppa||"-"}}</span>
                    </td>
                  </tr>
                  <tr class="dpjpEl" v-for="(v,k) in (dataCatatanAsuhan.catatanDPJPDokter||[])" :key="k">
                    <td>
                      <span>{{v.auldd_laporan_dpjp_tanggal|moment("DD MMMM YYYY")}}
                        {{v.auldd_laporan_dpjp_waktu||"-"}}</span>
                    </td>

                    <td>
                      <div class="tb-content">
                        <p class="font-weight-semibold text-blue-800">Melaporkan kondisi pasien ke DPJP</p>
                        <p><span class="font-weight-semibold">S</span>: Pasien datang ke UGD dengan keluhan utama
                          {{v.auldd_subjektif||"-"}} <span v-if="v.auldd_riwayat_penyakit">, Pasien mempunyai riwayat
                            penyakit dahulu: {{v.auldd_riwayat_penyakit||"-"}} </span></p>
                        <p><span class="font-weight-semibold">B</span>: Riwayat Alergi:
                          <ul class="mb-0">
                            <li v-for="(v1,k1) in (v.auldd_riwayat_alergi||[])" :key="k1"><span
                                class="font-weight-semibold">({{getConfigDynamic(Config.mr.jenisAlergi,v1.jenis)}})</span>{{v1.name||"-"}}
                            </li>
                          </ul>
                          <span v-if="!(v.auldd_riwayat_alergi||[]).length"> - </span>
                        </p>
                        <div class="mb-2">
                          <span class="font-weight-semibold">Keluhan Saat Ini</span>
                          <div>{{v.auldd_subjektif||"-"}}</div>
                        </div>
                        <div class="mb-2">
                          <span class="font-weight-semibold">Pengobatan yang dilakukan di UGD</span>
                          <div>{{v.auldd_catatan_dokter||"-"}}</div>
                        </div>
                        <div class="mb-2">
                          <span class="font-weight-semibold">Riwayat Pembedahan</span>
                          <div>
                            <span>{{v.auldd_laporan_dpjp_riwayat_pembedahan||"-"}}</span>
                          </div>
                        </div>
                        <div class="mb-2">
                          <span class="font-weight-semibold">Tindakan Invasif</span>
                          <template v-if="(v.auldd_laporan_dpjp_tindakan_invasif||[]).length">
                            <ol class="mb-0" v-for="(v,k) in (v.auldd_laporan_dpjp_tindakan_invasif||[])" :key="k">
                              <li>{{v}}</li>
                            </ol>
                          </template>
                          <p v-else>Tidak ada Tindakan</p>
                        </div>
                        <div class="mb-2">
                          <span class="font-weight-semibold">A: </span>Keluhan saat ini
                          <div><span>{{v.auldd_laporan_dpjp_keluhan||"-"}}</span></div>
                        </div>
                        <div class="mb-2">
                          <span class="font-weight-semibold">Hasil Skor Nyeri</span>
                          <div>{{v.auldd_hasil_skoring_nyeri}}
                            <template
                              v-if="(dataCatatanAsuhan.row.ap_usia > 6 || (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null && dataCatatanAsuhan.rowDataDokter.auod_nyeri_type == 'NP'))">
                              <span class="border-left ml-2 pl-2 font-weight-semibold"
                                v-if="v.auldd_hasil_skoring_nyeri == 0">Tidak Ada Nyeri</span>
                              <span class="border-left ml-2 pl-2 font-weight-semibold"
                                v-else-if="v.auldd_hasil_skoring_nyeri >= 1 && v.auldd_hasil_skoring_nyeri <= 3">Sedikit
                                Nyeri</span>
                              <span class="border-left ml-2 pl-2 font-weight-semibold"
                                v-else-if="v.auldd_hasil_skoring_nyeri >= 4 && v.auldd_hasil_skoring_nyeri <= 5">Agak
                                Mengganggu</span>
                              <span class="border-left ml-2 pl-2 font-weight-semibold"
                                v-else-if="v.auldd_hasil_skoring_nyeri >= 6 && v.auldd_hasil_skoring_nyeri <= 7">Mengganggu
                                Aktivitas</span>
                              <span class="border-left ml-2 pl-2 font-weight-semibold"
                                v-else-if="v.auldd_hasil_skoring_nyeri >= 8 && v.auldd_hasil_skoring_nyeri <= 9">Sangat
                                Mengganggu</span>
                              <span class="border-left ml-2 pl-2 font-weight-semibold"
                                v-else-if="v.auldd_hasil_skoring_nyeri >= 10">Tak Tertahankan</span>
                            </template>

                            <template
                              v-if="(dataCatatanAsuhan.row.ap_usia <= 6) || (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null)">
                              <template
                                v-if="(dataCatatanAsuhan.row.ap_usia > 1 || (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null && dataCatatanAsuhan.rowDataDokter.auod_nyeri_type == 'FL'))">
                                <span v-if="v.auldd_hasil_skoring_nyeri == 0"
                                  class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                                <span v-else-if="v.auldd_hasil_skoring_nyeri >= 1 && v.auldd_hasil_skoring_nyeri < 4"
                                  class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan ringan</span>
                                <span v-else-if="v.auldd_hasil_skoring_nyeri >= 4 && v.auldd_hasil_skoring_nyeri < 7"
                                  class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri
                                  Parah</span>
                              </template>

                              <template
                                v-if="((dataCatatanAsuhan.row.ap_usia <= 1  && dataCatatanAsuhan.row.ap_usia != null)|| (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null && dataCatatanAsuhan.rowDataDokter.auod_nyeri_type == 'NIPS')) ">
                                <span v-if="v.auldd_hasil_skoring_nyeri < 3"
                                  class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Ringan, Tidak Nyeri</span>
                                <span v-else-if="v.auldd_hasil_skoring_nyeri >= 3 && v.auldd_hasil_skoring_nyeri < 5"
                                  class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang - Nyeri Ringan
                                  (intervensi tanpa obat, dievaluasi selama 30 menit)</span>
                                <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Hebat (intervensi
                                  tanpa obat, bila masih nyeri diberikan analgesik dan dievaluasi selama 30
                                  menit)</span>
                              </template>
                            </template>
                          </div>
                        </div>
                        <div class="mb-2">
                          <span class="font-weight-semibold">Tanda-tanda Vital</span>
                          <ul class="mb-0">

                            <li>Tekanan Darah:
                              {{v.auldd_ttv_tekanan_darah_min||"-"}}/{{v.auldd_ttv_tekanan_darah_max||"-"}} mmHG</li>
                            <li>Nadi: {{v.auldd_ttv_nadi||"-"}} x/mnt - {{v.auldd_ttv_label||"-"}}</li>
                            <li>Gula Darah: {{v.auldd_ttv_gula_darah||"-"}} mg/dL</li>
                            <li>Pernafasan: {{v.auldd_ttv_pernafasan||"-"}} x/mnt</li>
                            <li>SPO2: {{v.auldd_ttv_spo2||"-"}}%</li>
                            <li>Suhu: {{v.auldd_ttv_suhu||"-"}}C</li>
                            <li>Berat Badan {{v.auldd_ttv_weight||"-"}} kg</li>
                            <li>Tinggi: {{v.auldd_ttv_height||"-"}} cm</li>
                            <li>Lingkar Kepala: {{v.auldd_ttv_lingkar_kepala||"-"}} cm</li>
                            <li>Luas Permukaan Tubuh Anak: {{v.auldd_ttv_luas_permukaan_anak||"-"}} M<sup>2</sup></li>
                            <li>Kesadaran: <span>{{v.auldd_hasil_kesadaran}} |

                                <span v-if="v.auldd_hasil_kesadaran >= 14"> Composmentis</span>
                                <span
                                  v-else-if="v.auldd_hasil_kesadaran >= 12 && v.auldd_hasil_kesadaran <= 13">Apatis</span>
                                <span
                                  v-else-if="v.auldd_hasil_kesadaran >= 10 && v.auldd_hasil_kesadaran <= 11">Delirium</span>
                                <span
                                  v-else-if="v.auldd_hasil_kesadaran >= 7 && v.auldd_hasil_kesadaran <= 9">Somonolen</span>
                                <span
                                  v-else-if="v.auldd_hasil_kesadaran >= 5 && v.auldd_hasil_kesadaran <= 6">Sopor</span>
                                <span
                                  v-else-if="v.auldd_hasil_kesadaran >= 4 && v.auldd_hasil_kesadaran <= 4">Semi-Comma</span>
                                <span v-else>Coma</span>

                                <span class="ml-2" style="margin-right:20px;">E:
                                  {{v.auldd_hasil_kesadaran_e||0}}
                                </span>
                                <span style="margin-right:20px;">M:
                                  {{v.auldd_hasil_kesadaran_m||0}}
                                </span>
                                <span style="margin-right:20px;">V:
                                  {{v.auldd_hasil_kesadaran_v||0}}
                                </span>
                              </span></li>
                          </ul>
                        </div>
                        <div class="mb-2">
                          <span class="font-weight-semibold">Tindakan Lab</span>
                          <template v-if="(v.auldd_hasil_lab||[]).length">
                            <ol class="mb-0" v-for="(v3,k3) in (v.auldd_hasil_lab||[])" :key="k3">
                              <li>{{v3.value}}</li>
                            </ol>
                          </template>
                          <p v-else> - </p>
                        </div>
                        <div class="mb-2">
                          <span class="font-weight-semibold">Tindakan Radiologi</span>
                          <template v-if="(v.auldd_hasil_radiologi||[]).length">
                            <ol class="mb-0" v-for="(v4,k4) in (v.auldd_hasil_radiologi||[])" :key="k4">
                              <li>{{v4.value}}</li>
                            </ol>
                          </template>
                          <p v-else> - </p>
                        </div>
                        <div class="mb-2">
                          <span class="font-weight-semibold">R</span>
                          <div class="mb-1">
                            <span>Rekomendasi Intervensi yang Perlu Dilanjutkan</span>
                            <template v-if="(v.auldd_laporan_dpjp_rekomendasi_intervensi||[]).length">
                              <ol class="mb-0" v-for="(v,k) in (v.auldd_laporan_dpjp_rekomendasi_intervensi||[])"
                                :key="k">
                                <li>{{v}}</li>
                              </ol>
                            </template>
                            <p v-else> - </p>


                          </div>
                          <div class="mb-1">
                            <span>Usulan kolaborasi</span>
                            <template v-if="(v.auldd_laporan_dpjp_usulan_kolaborasi||[]).length">
                              <ol class="mb-0" v-for="(v,k) in (v.auldd_laporan_dpjp_usulan_kolaborasi||[])" :key="k">
                                <li>{{v}}</li>
                              </ol>
                            </template>
                            <p v-else> - </p>

                          </div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <span>{{v.auldd_laporan_dpjp_tanggal_evaluasi}} {{v.auldd_laporan_dpjp_waktu_evaluasi}}</span>
                    </td>

                    <td>
                      <div class="form-group" v-for="(vU,kU) in (v.auldd_laporan_dpjp_evaluasi_data||[])" :key="kU">
                        <label for=""><b>Evaluasi #{{kU+1}}</b></label>
                        <pre class="pre-input mb-1">{{ v.auldd_laporan_dpjp_evaluasi_data[kU]['value']||'-' }}</pre>

                        <span v-if="v.auldd_laporan_dpjp_evaluasi_data[kU]['is_done'] == 'Y'">Selesai pada
                          {{v.auldd_laporan_dpjp_evaluasi_data[kU]['done_date']}}</span>

                      </div>
                      <div id="verifikasiRanap">
                        <p class="font-weight-semibold text-blue-800">Data sudah diverifikasi oleh:</p>

                        <div class="form-group">
                          <label for="">Nama DPJP</label>
                          <p>{{v.nama_dokter_ranap||"-"}}
                          </p>
                        </div>

                        <div class="form-group">
                          <label>Tanggal</label>
                          <p>{{v.aupd_laporan_dpjp_evaluasi_date||"-"}}</p>
                        </div>

                        <div class="form-group">
                          <label>Jam</label>
                          <p>{{v.aupd_laporan_dpjp_evaluasi_waktu||"-"}}</p>
                        </div>
                      </div>
                    </td>

                    <td>
                      <span>{{v.auldd_laporan_dpjp_ppa||"-"}}</span>
                    </td>
                  </tr>
                </template>
                <tr class="table-info">
                  <td colspan="6"><span class="font-weight-semibold text-info-600">Catatan Asuhan Lainnya</span></td>
                </tr>
              </template>

              <tr v-for="(v,k) in (dataCatatanAsuhan.data||[])" :key="k">
                <td>
                  <span>{{v.aucap_date | moment("DD MMMM YYYY") }},{{v.aucap_jam||"-"}}</span>
                </td>
                <td>

                  <template v-if="v.aucap_type == 'L' || v['aucap_assesmen_tindakan'] == 'Free Text'">
                    <span v-if="v['aucap_assesmen_tindakan'] != 'Free Text'">{{v.aucap_assesmen_tindakan||"-"}}</span>
                    <span v-else>
                      {{v.aucap_assesmen_tindakan_text||"-"}}
                    </span>

                    <template v-if="v['aucap_assesmen_tindakan'] == 'Melaksanakan pemberian injeksi'">
                      <div class="form-inline">
                        <label>Injeksi pada</label>
                        <span>{{v.aucap_assesmen_tindakan_sup_text||"-"}}</span>
                      </div>
                    </template>

                    <template v-if="v['aucap_assesmen_tindakan'] == 'Melakukan incici pada...'">
                      <div class="form-inline">
                        <label>Incici pada</label>
                        <span>{{v.aucap_assesmen_tindakan_sup_text||"-"}}</span>
                      </div>
                    </template>

                    <template v-if="v['aucap_assesmen_tindakan'] == 'Memasang gelang identitas warna...'">
                      <label>Warna gelang</label>
                      <span>{{v.aucap_assesmen_tindakan_sup_text||"-"}}</span>
                    </template>

                    <template v-if="v['aucap_assesmen_tindakan'] == 'Memberikan obat... dengan nebulizer'">
                      <label>Nama Obat</label>
                      <span>{{v.aucap_assesmen_tindakan_sup_text||"-"}}</span>
                    </template>



                    <template
                      v-if="v['aucap_assesmen_tindakan'] == 'Memberikan obat melalui titrasi syring pump dengan dosis'">
                      <div class="mb-2">
                        <label>Nama Obat</label>
                        <span>{{v.aucap_assesmen_tindakan_sup_text||"-"}}</span>
                      </div>

                      <div class="mb-2">
                        <label>Dosis</label>
                        <span>{{v.aucap_assesmen_tindakan_sup_text2||"-"}}</span>
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <div>
                      <span>{{v.aucap_assesmen_tindakan_text||"-"}}</span>
                    </div>
                  </template>

                </td>
                <td>
                  <span>{{v.aucap_evaluasi_date | moment("DD MMMM YYYY") }},{{v.aucap_evaluasi_jam||"-"}}</span>
                </td>
                <td>
                  <template v-if="v.aucap_type == 'L'">

                    <div v-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memberikan oksigen'">
                      <div class="mb-2 evalContent">
                        <div class="form-inline">
                          <label>Oksigen terpasang</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                          <label>L/menit, SpO2</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>
                        </div>
                      </div>
                    </div>
                    <div class=""
                      v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan Perekaman EKG'">
                      <div class="mb-2 evalContent">
                        <span>Hasil Terlampir</span>
                      </div>
                    </div>
                    <div class=""
                      v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memasang bedside monitor'">
                      <div class="mb-2 evalContent">
                        <span>Monitor terpasang</span>
                      </div>
                    </div>
                    <div class=""
                      v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan pemeriksaan cek gula darah acak'">
                      <div class="mb-2 evalContent">
                        <div class="form-inline">
                          <label>Hasil GDA</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                        </div>
                      </div>
                    </div>
                    <div class=""
                      v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan pemasangan infus sesuai dengan advis dokter'">
                      <div class="mb-2 evalContent">
                        <div class="form-inline">
                          <label>Infus terpasang cairan</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                          <label>dengan tetesan</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>
                          <label>/menit tusukan pada</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t3||"-"}}</span>
                          <label>dengan abocath</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t4||"-"}}</span>
                        </div>
                      </div>
                    </div>
                    <div class=""
                      v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melaksanakan pemberian injeksi'">
                      <div class="mb-2 evalContent">
                        <div class="form-inline">
                          <label>Injeksi masuk lewat</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                          <label>tanda-tanda alergi</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>
                          <label>keluhan</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t3||"-"}}</span>
                        </div>
                      </div>
                    </div>
                    <div class=""
                      v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan pemasangan kateter tetap'">
                      <div class="mb-2 evalContent">
                        <div class="form-inline">
                          <label>Kateter terpasang nomor</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                          <label>dengan balon WFI</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>
                          <label>urin keluar</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t3||"-"}}</span>
                          <label>CC setelah pemasangan</label>
                        </div>
                      </div>
                    </div>
                    <div class=""
                      v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan suction'">
                      <div class="mb-2 evalContent">
                        <div class="form-inline">
                          <label>Lendir terhisap, suara nafas</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                        </div>
                      </div>
                    </div>
                    <div class=""
                      v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan penjahitan luka'">
                      <div class="mb-2 evalContent">
                        <div class="form-inline">
                          <label>Luka terjahit</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                          <label>. Simpul dengan jenis dan ukuran benang</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>
                        </div>
                      </div>

                    </div>
                    <div class=""
                      v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan perawatan luka'">
                      <div class="mb-2 evalContent">
                        <div class="form-inline">
                          <label>Kondisi luka setelah dilakukan perawatan</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                        </div>
                      </div>
                    </div>
                    <div class=""
                      v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan ekstraksi kuku'">
                      <div class="mb-2 evalContent">
                        <span>Kuku bisa terangkat, luka bersih</span>
                      </div>
                    </div>
                    <div class=""
                      v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan incici pada...'">
                      <div class="mb-2 evalContent">
                        <div class="form-inline">
                          <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                          <label>sudah terambil perawatan luka telah dilakukan, luka bekas incici bersih</label>
                        </div>
                      </div>
                    </div>
                    <div class=""
                      v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memasang gelang identitas warna...'">
                      <div class="mb-2 evalContent">
                        <div class="form-inline">
                          <label>Terpasang gelang identitas warna </label>
                          <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                          <label>Pasien maupun keluarga paham penjelasan petugas</label>
                        </div>
                      </div>
                    </div>
                    <div class=""
                      v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memasang gelang penanda alergi'">
                      <div class="mb-2 evalContent">
                        <div class="form-inline">
                          <label>Terpasang gelang alergi warna merah dengan tulisan alergen </label>
                          <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                        </div>
                      </div>
                    </div>
                    <div class=""
                      v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memasang bidai/spalk'">
                      <div class="mb-2 evalContent">
                        <div class="form-inline">
                          <label>Terpasang bidai pada</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                          <label>Hasil pengecekan</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>
                          <label>tanda tanda kompartemen</label>
                        </div>
                      </div>
                    </div>
                    <div class=""
                      v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan pengambilan benda asing/corpal'">
                      <div class="mb-2 evalContent">
                        <div class="form-inline">
                          <label>Benda asing pada</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                          <label>pada area</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>
                        </div>
                      </div>
                    </div>
                    <div class=""
                      v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan irigasi mata'">
                      <div class="mb-2 evalContent">
                        <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                      </div>
                    </div>
                    <div class=""
                      v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan lavement'">
                      <div class="mb-2 evalContent">
                        <div class="form-inline">
                          <label>Lavement masuk</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                          <label>CC dengan cairan yang digunakan</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>
                          <label>hasil evaluasi feses</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t3||"-"}}</span>
                        </div>
                      </div>
                    </div>
                    <div class=""
                      v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan pelepasan kateter tetap'">
                      <div class="mb-2 evalContent">
                        <span>Kateter berhasil dilakukan pelepasan dengan bleder training sebelumnya</span>
                      </div>
                    </div>
                    <div class=""
                      v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memberikan obat... dengan nebulizer'">
                      <div class="mb-2 evalContent">
                        <div class="form-inline">
                          <label>Nebulizer telah dilakukan, sesak nafas</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                          <label>SpO2</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>
                          <label>sputum</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t3||"-"}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memasang NGT'">
                      <div class="mb-2 evalContent">
                        <div class="form-inline">
                          <label>NGT terpasang dengan ukuran</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                          <label>pada hidung sebelah</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>
                          <label>hasil evaluasi terdengar bunyi blup pada area lambung</label>
                        </div>
                      </div>
                    </div>
                    <div class=""
                      v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan perawatan luka combutio'">
                      <div class="mb-2 evalContent">
                        <div class="form-inline">
                          <label>Luka tampak</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                          <label>Bula</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>
                          <label>perawatan menggunakan topical terapi</label>
                          <span class="ml-1">{{v.aucap_evaluasi_t3||"-"}}</span>
                        </div>
                      </div>
                    </div>
                    <div class=""
                      v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memberikan obat melalui titrasi syring pump dengan dosis'">
                      <div class="mb-2 evalContent">
                        <span>Obat masuk melalui syring pump, lancar</span>
                      </div>
                    </div>
                    <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan CTG'">
                      <div class="mb-2 evalContent">
                        <span>Hasil terlampir</span>
                      </div>
                    </div>
                    <div class=""
                      v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memfasilitasi pasien untuk pemeriksaan radiologi'">
                      <div class="mb-2 evalContent">
                        <span>Hasil terlampir</span>
                      </div>
                    </div>

                    <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Free Text'">
                      <span>{{dataCatatanAsuhan.data[k]['aucap_evaluasi_text']||"-"}}</span>
                    </div>


                  </template>
                  <template v-else>
                    <span class="ml-1">{{v.aucap_evaluasi_text||"-"}}</span>
                  </template>

                </td>
                <td>
                  <span>{{v.aucap_ppa}}</span>
                </td>
                <td>
                  <span v-if="v.aucap_is_done != 'Y'"> - </span>
                  <span v-else>Selesai pada {{v.aucap_done_date | moment("DD MMMM YYYY, HH:mm")}}</span>
                </td>
              </tr>
              <tr v-if="!(dataCatatanAsuhan.data||[]).length">
                <td colspan="6" class="text-center">Tidak ada data</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered table-sm table-align-top">
            <thead>
              <tr>
                <td colspan="6" class="bg-indigo">
                  <span class="font-weight-semibold">Catatan Asuhan Dokter</span>
                </td>
              </tr>
              <tr>
                <th>Waktu</th>
                <th>Asesmen/Tindakan</th>
                <th>Waktu Evaluasi</th>
                <th>Evaluasi</th>
                <th>PPA</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v,k) in (dataCatatanAsuhan.dataKajianDokter||[])" :key="k">
                <td>
                  <span>{{v.aucad_tanggal | moment("DD MMMM YYYY")}}, {{v.aucad_jam}}</span>
                </td>
                <td>
                  <span>{{v.aucad_assesmen||"-"}}</span>
                </td>
                <td>
                  <span>{{v.aucad_tanggal_evaluasi | moment("DD MMMM YYYY")}}, {{v.aucad_jam_evaluasi||"-"}}</span>
                </td>
                <td>
                  <span>{{v.aucad_evaluasi||"-"}}</span>
                </td>
                <td>
                  <span>{{v.aucad_ppa||"-"}}</span>
                </td>
                <td>
                  <span v-if="v.aucad_is_active != 'Y'"> - </span>
                  <span v-else>Selesai pada {{v.aucad_done_at | moment("DD MMMM YYYY, HH:mm")}}</span>
                </td>
              </tr>
              <tr v-if="!(dataCatatanAsuhan.dataKajianDokter||[]).length">
                <td colspan="6" class="text-center">Tidak ada data</td>
              </tr>
            </tbody>
          </table>
        </div>

        <template v-if="Object.keys(dataCatatanAsuhan||{}).length ">
          <div class="table-responsive">
            <table v-if="showIntervensiCA || (row.ap_usia == null && row.ap_dob == null && (dataCatatanAsuhan.dataKajianPerawat.aukprj_ed_value >= 90 || dataCatatanAsuhan.dataKajianPerawat.aukprj_humpty_value >= 12 || dataCatatanAsuhan.dataKajianPerawat.aukprj_morshe_value >= 25 || 
                    getTotalSydneyCA >=6))" class="table table-bordered table-sm table-striped">
              <thead>
                <tr>
                  <td colspan="2" class="bg-indigo">
                    <span class="font-weight-semibold">Intervensi Risiko Jatuh</span>
                  </td>
                </tr>
                <tr>
                  <th width="50%">Tindakan</th>
                  <th width="50%">Evaluasi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_gelang == 'Y'">
                  <td>
                    <label class="mb-0">Memasang gelang kuning risiko jatuh</label>
                    <p>{{dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_gelang == 'Y' ? 'Ya':'Tidak'}}</p>
                  </td>

                  <td>
                    <label class="mb-0">Terpasang gelang warna kuning pada</label>
                    <p>{{dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_gelang_text || "-"}}</p>
                  </td>

                </tr>
                <tr v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_pengaman == 'Y'">
                  <td>
                    <label class="mb-0">Memasang gelang kuning risiko jatuh</label>
                    <p>{{dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_pengaman == 'Y' ? 'Ya':'Tidak'}}</p>
                  </td>
                  <td>
                    <span v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_pengaman == 'Y'">Pagar pengaman
                      tempat tidur terpasang pada kedua sisi</span>
                    <span v-else> - </span>
                  </td>
                </tr>
                <tr v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_roda == 'Y'">
                  <td>
                    <label class="mb-0">Mengunci roda tempat tidur</label>
                    <p>{{dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_roda == 'Y' ? 'Ya':'Tidak'}}</p>
                  </td>
                  <td>
                    <span v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_roda == 'Y'">Roda tempat tidur
                      terkunci dan tidak mudah tergeser</span>
                    <span v-else> - </span>
                  </td>
                </tr>
                <tr v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_restrain == 'Y'">
                  <td>
                    <label class="mb-0">Memasang restrain</label>
                    <p>{{dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_restrain == 'Y' ? 'Ya':'Tidak'}}</p>
                  </td>
                  <td>
                    <label class="mb-0">Terpasang restrain di</label>
                    <p v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_restrain == 'Y'">
                      {{dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_restrain_text||"-"}}</p>
                    <p v-else> - </p>
                  </td>
                </tr>
                <tr v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_edukasi == 'Y'">
                  <td>
                    <label class="mb-0">Memberikan edukasi pencegahan jatuh pada pasien dan keluarga</label>
                    <p>{{dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_edukasi == 'Y' ? 'Ya':'Tidak'}}</p>
                  </td>
                  <td>
                    <span v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_edukasi == 'Y'">Memberikan edukasi
                      pencegahan jatuh pada pasien dan keluarga</span>
                    <span v-else> - </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </b-modal>


      <b-modal v-model="openRadiologi" title="Dokumen Radiologi" size="xl" @ok.prevent="submitRadiologi"
        ok-title="Simpan dan Lanjutkan">
        <div class="row">
          <div class="col-md-12">
            <ul class="nav nav-tabs nav-tabs-bottom nav-justified mt-2 mb-0">
              <li class="nav-item"><a href="javascript:;" @click="changeTabRadiologi(1)" data-toggle="tab"
                  :class="activeTabRadiologi == 1 ?'nav-link active':'nav-link'">Informed Consent</a></li>
              <li class="nav-item"><a href="javascript:;" @click="changeTabRadiologi(2)" data-toggle="tab"
                  :class="activeTabRadiologi == 2 ?'nav-link active':'nav-link'">Transfer Intra Penunjang</a></li>
            </ul>
            <div class="tab-content mt-2">

              <div :class="activeTabRadiologi == 1 ? 'tab-pane fade show active' : 'tab-pane'" id="radInformedConsent">
                <h6>Tindakan Radiologi Dokter</h6>
                <table class="table table-bordered table-striped table-hover table-sm patient-table">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Nama Tindakan</th>
                      <th>Tindakan Disetujui Oleh Pasien</th>
                      <th>Dokumen</th>
                      <th>Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v,k) in (dataTindakanRadiologi||[])" :key="k">
                      <td>{{k+1}}</td>
                      <td>{{v.text}} ({{getConfigDynamic(Config.mr.KategoriRadiologi,v.jenis)}})</td>
                      <td>
                        <span v-if="v.auic_is_pasien_setuju">
                          {{v.auic_is_pasien_setuju == 'Y'?'Ya':'Tidak'}}
                        </span>
                        <span v-else> - </span>
                      </td>
                      <td>
                        <a v-if="v.auic_file" :href="uploader(v.auic_file)" target="_blank"
                          class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700"
                          v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                        <span v-else> - </span>
                      </td>
                      <td>

                        <a href="javascript:;"
                          v-if="(dataInformedConsentPetugasRadiologi||[]).findIndex(x=>x.auic_tindakan_radiologi == v.id) == -1"
                          @click="addInformConsent(v)"
                          class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700 ml-1"
                          v-b-tooltip.hover.right title="Tambah Informed Consent Radiologi"><i
                            class="icon-plus-circle2"></i></a>
                        <span v-else> - </span>

                      </td>
                    </tr>
                    <tr v-if="!(dataTindakanRadiologi||[]).length">
                      <td colspan="5" class="text-center">Tidak ada Tindakan</td>
                    </tr>
                  </tbody>
                </table>

                <h6 class="mt-3">Tindakan Inform Consent Oleh Petugas Radiologi</h6>
                <table class="table table-bordered table-striped table-hover table-sm patient-table">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Nama Tindakan</th>
                      <th>Tindakan Disetujui Oleh Pasien</th>
                      <th>Dokumen</th>
                      <th>Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v,k) in (dataInformedConsentPetugasRadiologi||[])" :key="k">
                      <td>{{k+1}}</td>
                      <td>{{v.auic_name}}</td>
                      <td>
                        <span v-if="v.auic_is_pasien_setuju">
                          {{v.auic_is_pasien_setuju == 'Y'?'Ya':'Tidak'}}
                        </span>
                        <span v-else> - </span>
                      </td>
                      <td>
                        <a v-if="v.auic_file" :href="uploader(v.auic_file)" target="_blank"
                          class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700"
                          v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                        <span v-else>

                          <a href="javascript:;" @click="openTemplateIC(v)"
                            class="btn alpha-success border-success text-success-800 mb-1">
                            <b><i class="icon-download"></i></b>
                            <span class="ml-2">Unduh Template Dokumen</span>
                          </a>

                          <Uploader @input="uploadDok(v,k)" v-model="v.auic_file" isDocument type="doc_only" />
                        </span>


                      </td>
                      <td>
                        <a href="javascript:;" @click="editInformConsent(v)"
                          class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700 ml-1"
                          v-b-tooltip.hover.right title="Edit Tindakan"><i class="icon-pencil5"></i></a>

                        <a href="javascript:;" @click="removeInformConsent(v)"
                          class="btn btn-sm alpha-danger border-danger btn-icon rounded-round text-danger-700 ml-1"
                          v-b-tooltip.hover.right title="Hapus Tindakan"><i class="icon-bin"></i></a>
                      </td>
                    </tr>
                    <tr v-if="!(dataInformedConsentPetugasRadiologi||[]).length">
                      <td colspan="99" class="text-center">Tidak ada Tindakan</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div :class="activeTabRadiologi == 2 ? 'tab-pane fade show active' : 'tab-pane'" id="radIntraPenunjang">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="">Tanda Tangan Pemberi</label>
                      <div class="signing-element" data-target-id="ttdPemberi">
                        <VueSignaturePad class="sign-container" width="100%" height="160px" ref="ttdPemberi" />
                        <div class="mt-2">
                          <button type="button" @click="undoTTD('ttdPemberi','aur_radiologi_pemberi_ttd')"
                            class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                            <b><i class="icon-cross3"></i></b>
                            <span>Clear</span>
                          </button>

                          <button type="button" @click="output('ttdPemberi','aur_radiologi_pemberi_ttd')"
                            class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                            <b><i class="icon-checkmark2"></i></b>
                            <span>Simpan</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="">Tanda Tangan Pemberi</label>
                      <b-form-input v-model="namaPemberi" placeholder="Nama Pemberi" />
                    </div>

                    <div class="form-group mt-3">
                      <label for="">Tanda Tangan Penerima</label>
                      <div class="signing-element" data-target-id="ttdRadiologi">
                        <VueSignaturePad class="sign-container" width="100%" height="160px" ref="ttdRadiologi" />
                        <div class="mt-2">
                          <button type="button" @click="undoTTD('ttdRadiologi','aur_radiologi_ttd')"
                            class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                            <b><i class="icon-cross3"></i></b>
                            <span>Clear</span>
                          </button>

                          <button type="button" @click="output('ttdRadiologi','aur_radiologi_ttd')"
                            class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                            <b><i class="icon-checkmark2"></i></b>
                            <span>Simpan</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="">Tanda Tangan Penerima</label>
                      <b-form-input v-model="namaPetugasRadiologi" placeholder="Nama Penerima" />
                    </div>

                  </div>
                  <div class="col-md-8">
                    <div class="document-framer">
                      <div style="position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 14px;">
                        <div>
                          <table style="border-collapse: collapse;width: 100%;font-size: 13px;">
                            <tbody>
                              <tr>
                                <td>
                                  <div
                                    style="float: left;width: 10%;vertical-align: middle;box-sizing: border-box;padding-right: 8px;">
                                    <img :src="assetLocal('global_assets/images/logo-rsh-mark.png')" alt="">
                                  </div>
                                  <div
                                    style="text-transform: uppercase;float: left;width: 90%;vertical-align: middle;box-sizing: border-box;padding-left: 35px;">
                                    <h1 style="margin:0;font-size: 14px;line-height: 20px;margin-bottom: 2px;">Rumah
                                      Sakit Harapan Magelang</h1>
                                    <p style="margin:0;">Jl.P Senopati No 11, Magelang 56123</p>
                                    <p style="margin:0;">Telp. (0293)-364033 s/d 364035</p>
                                    <p style="margin:0;">Fax. (0293-364037)</p>
                                  </div>
                                </td>
                                <td style="text-align: right;">
                                  <span>Magelang, {{now}}</span>
                                </td>
                              </tr>
                              <tr>
                                <th colspan="2" style="padding: 1px 4px;text-align:center;">
                                  <h2
                                    style="font-size: 14px;text-transform: uppercase;display: inline-block;margin-bottom: 4px;font-weight:bold;">
                                    Transfer Intra Rumah Sakit</h2>
                                  <div style="font-weight: normal;font-size:14px">Penunjang</div>
                                </th>
                              </tr>
                              <tr>
                                <td colspan="2" style="padding: 1px 4px;">
                                  <table style="font-size: 14px;width: 100%;line-height: 1.5;">
                                    <tbody>
                                      <tr>
                                        <th style="text-align: left;vertical-align: top;" rowspan="4">Situation</th>
                                        <td style="padding: 1px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Nama</strong></div>
                                            <span>{{row.ap_fullname||"-"}}</span>
                                          </div>
                                        </td>
                                        <td style="padding: 1px 4px;" colspan="2">
                                          <div>
                                            <div><strong>No. RM</strong></div>
                                            <span>{{row.ap_code||"-"}}</span>
                                          </div>
                                        </td>
                                        <td style="padding: 1px 4px;" colspan="2">
                                          <div>
                                            <div><strong>NIK</strong></div>
                                            <span>{{row.ap_nik||"-"}}</span>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 1px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Tanggal Lahir</strong></div>
                                            <span v-if="row.ap_dob">
                                              {{row.ap_dob | moment("DD MMMM YYYY")}}
                                            </span>
                                          </div>
                                        </td>
                                        <td style="padding: 1px 4px;" colspan="2">
                                          <div>
                                            <div><strong>DPJP</strong></div>
                                            <span>{{rowReg.bu_full_name||"-"}}</span>
                                          </div>
                                        </td>
                                        <td style="padding: 1px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Jam Datang</strong></div>
                                            <span>
                                              {{rowIntraRS.auirs_jam_datang||"-"}}</span>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 1px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Diagnosa</strong></div>
                                            <span>{{rowIntraRS.auirs_diagnosa||"-"}}</span>
                                          </div>
                                        </td>
                                        <td style="padding: 1px 4px;" colspan="4">
                                          <div>
                                            <div><strong>Kondisi Saat Ini</strong></div>
                                            <span>{{rowIntraRS.auirs_kondisi_saat_ini||"-"}}</span>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 1px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Dari Ruang</strong></div>
                                            <span>{{rowIntraRS.auirs_dari_ruang||"-"}}</span>
                                          </div>
                                        </td>
                                        <td style="padding: 1px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Ke Ruang</strong></div>
                                            <span>Radiologi</span>
                                          </div>
                                        </td>
                                        <td style="padding: 1px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Jam Pindah</strong></div>
                                            <span>{{rowIntraRS.auirs_created_date | moment("DD MMMM YYYY, HH:mm")}}</span>
                                          </div>
                                        </td>
                                      </tr>

                                    </tbody>
                                    <tbody>
                                      <tr>
                                        <th style="text-align: left;vertical-align: top;" rowspan="6">Background</th>
                                        <td style="padding: 1px 4px;" colspan="3">
                                          <div>
                                            <div><strong>Intervensi yang Telah Dilakukan</strong></div>
                                            <span>{{rowIntraRS.auirs_background_intervensi||"-"}} </span>
                                          </div>
                                        </td>
                                        <td style="padding: 1px 4px;" colspan="3">
                                          <div>
                                            <div><strong>Riwayat Alergi</strong></div>
                                            <span>{{rowIntraRS.auirs_riwayat_alergi||"-"}}</span>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 1px 4px;" colspan="3">
                                          <div>
                                            <div><strong>Riwayat Pembedahan</strong></div>
                                            <span>{{rowIntraRS.auirs_riwayat_pembedahan||"-"}}</span>
                                          </div>
                                        </td>
                                        <td style="padding: 1px 4px;" colspan="3">
                                          <div>
                                            <div><strong>Tindakan Invasif</strong></div>
                                            <ul v-if="(rowIntraRS.auirs_tindakan_invasif||[]).length">
                                              <li v-for="(v,k) in (rowIntraRS.auirs_tindakan_invasif||[])" :key="k">
                                                {{v||"-"}}
                                              </li>
                                            </ul>
                                            <span v-else> - </span>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 1px 4px;" colspan="3">
                                          <div>
                                            <div><strong>Obat-obatan yang diberikan</strong></div>
                                            <span>{{rowIntraRS.auirs_obat_obatan_yang_diberikan||"-"}}</span>

                                          </div>
                                        </td>
                                        <td style="padding: 1px 4px;" colspan="3">
                                          <div>
                                            <div><strong>Cairan Parametral yang diberikan</strong></div>
                                            <span>{{rowIntraRS.auirs_cairan_parental_yang_diberikan||"-"}}</span>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>

                                    <tbody>
                                      <tr>
                                        <th style="text-align: left;vertical-align: top;" rowspan="5">Assessment</th>
                                        <td style="padding: 1px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Kondisi Pasien Terkini</strong></div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 1px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Skala Nyeri</strong></div>
                                            <p>{{rowIntraRS.auirs_skala_nyeri_value||0}}
                                              <span v-if="rowIntraRS.auirs_skala_nyeri_value == 0"
                                                class="border-left ml-2 pl-2 font-weight-semibold">Santai dan
                                                Nyaman</span>
                                              <span
                                                v-else-if="rowIntraRS.auirs_skala_nyeri_value >= 1 && rowIntraRS.auirs_skala_nyeri_value < 4"
                                                class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan
                                                Ringan</span>
                                              <span
                                                v-else-if="rowIntraRS.auirs_skala_nyeri_value >= 4 && rowIntraRS.auirs_skala_nyeri_value < 7"
                                                class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                              <span v-else
                                                class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri
                                                Parah</span>
                                              <br />(Numeric Rating Scale)
                                            </p>
                                          </div>
                                        </td>
                                        <td style="padding: 1px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Tingkat Kesadaran</strong></div>
                                            <p>{{rowIntraRS.auirs_skala_nyeri_value||0}}
                                              <span v-if="rowIntraRS.auirs_skala_nyeri_value >= 14"
                                                class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span>
                                              <span
                                                v-else-if="rowIntraRS.auirs_skala_nyeri_value >= 12 && rowIntraRS.auirs_skala_nyeri_value <= 13"
                                                class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                              <span
                                                v-else-if="rowIntraRS.auirs_skala_nyeri_value >= 10 && rowIntraRS.auirs_skala_nyeri_value <= 11"
                                                class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                              <span
                                                v-else-if="rowIntraRS.auirs_skala_nyeri_value >= 7 && rowIntraRS.auirs_skala_nyeri_value <= 9"
                                                class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                                              <span
                                                v-else-if="rowIntraRS.auirs_skala_nyeri_value >= 5 && rowIntraRS.auirs_skala_nyeri_value <= 6"
                                                class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                              <span
                                                v-else-if="rowIntraRS.auirs_skala_nyeri_value >= 4 && rowIntraRS.auirs_skala_nyeri_value <= 4"
                                                class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                              <span v-else
                                                class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                              <br />(GCS)</p>
                                          </div>
                                        </td>
                                        <td style="padding: 1px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Risiko Jatuh</strong></div>
                                            <p>{{rowIntraRS.auirs_skala_nyeri_value||0}}
                                              <span v-if="rowIntraRS.auirs_skala_nyeri_value < 7"
                                                class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                                              <span
                                                v-else-if="rowIntraRS.auirs_skala_nyeri_value >= 7 && rowIntraRS.auirs_skala_nyeri_value <= 11"
                                                class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
                                              <span v-else-if="rowIntraRS.auirs_skala_nyeri_value >= 12"
                                                class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
                                              <br />
                                                <span v-if="row.ap_usia <= 18">(Kajian Humpty Dumpty)</span>
                                                <span v-else-if="row.ap_usia > 18 && row.ap_usia <= 60">(Kajian Morse Fall Scale)</span>
                                                <span v-else>(Kajian Skala Ontario Modified Stratify - Sydney Scoring)</span>
                                              </p>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 1px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Tekanan Darah</strong></div>
                                            <span>{{rowIntraRS.auirs_tekanan_darah_min||"-"}}/{{rowIntraRS.auirs_tekanan_darah_max||"-"}}
                                              mmHG</span>
                                          </div>
                                        </td>
                                        <td style="padding: 1px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Nadi</strong></div>
                                            <span>{{rowIntraRS.auirs_nadi||"-"}}x per menit</span>
                                          </div>
                                        </td>
                                        <td style="padding: 1px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Suhu</strong></div>
                                            <span>{{rowIntraRS.auirs_suhu||"-"}} &deg;C</span>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 1px 4px;" colspan="2">
                                          <div>
                                            <div><strong>RR</strong></div>
                                            <span>{{rowIntraRS.auirs_respiration||"-"}}x per menit</span>
                                          </div>
                                        </td>
                                        <td style="padding: 1px 4px;" colspan="2">
                                          <div>
                                            <div><strong>SpO2</strong></div>
                                            <span>{{rowIntraRS.auirs_spo2||"-"}}</span>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 1px 4px;" colspan="6">
                                          <div>
                                            <div><strong>Pemeriksaan Radiologi</strong></div>
                                            <span></span>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                    <tbody>
                                      <tr>
                                        <th style="text-align: left;vertical-align: top;" rowspan="4">Recommendation
                                        </th>
                                        <td style="padding: 1px 4px;" colspan="6">
                                          <div>
                                            <div><strong>Tindakan yang perlu dilanjutkan</strong></div>
                                            <span>{{rowIntraRS.auirs_tindakan||"-"}}</span>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 1px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Usulan Kolaborasi</strong></div>
                                            <span>{{rowIntraRS.auirs_usulan_kolab||"-"}}</span>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>

                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table style="width: 100%;font-size: 14px;">
                            <tbody>
                              <tr>
                                <br />
                                <td style="text-align: center;">
                                  <span>Pemberi</span><br />
                                  <img
                                    :src="rowReg.aur_radiologi_pemberi_ttd ? rowReg.aur_radiologi_pemberi_ttd: 'https://placehold.co/400x160'"
                                    alt="signature" width="200" id="ttdRadiologi"><br />
                                  <span v-if="rowReg.aur_radiologi_pemberi_ttd">({{namaPemberi||"-"}})</span>
                                </td>
                                <td style="text-align: center;">
                                  <span>Penerima</span><br />
                                  <img
                                    :src="rowReg.aur_radiologi_ttd ? rowReg.aur_radiologi_ttd: 'https://placehold.co/400x160'"
                                    alt="signature" width="200" id="ttdRadiologi"><br />
                                  <span v-if="rowReg.aur_radiologi_ttd">({{namaPetugasRadiologi||"-"}})</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </b-modal>

      <b-modal v-model="openDokumenRadiologi" title="Dokumen Radiologi" size="xl" ok-only>
        <div class="row">
          <div class="col-md-12">
            <table class="table table-bordered table-striped table-hover table-sm patient-table">
              <thead>
                <tr>
                  <th colspan="3">Dokumen Transfer Intra Penunjang</th>
                  <th>
                    <a @click="downloadFileIntra(rowReg.aur_id,'SuratIntraRS')" href="javascript:;"
                      class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700"
                      v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                  </th>
                </tr>
                <tr>
                  <th colspan="4">Dokumen Inform Consent</th>
                </tr>
                <tr>
                  <th>No</th>
                  <th>Nama Tindakan</th>
                  <th>Tindakan Disetujui Oleh Pasien</th>
                  <th>Dokumen</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v,k) in (dataTindakanRadiologi||[])" :key="k">
                  <td>{{k+1}}</td>
                  <td>{{v.text}} ({{getConfigDynamic(Config.mr.KategoriRadiologi,v.jenis)}})</td>
                  <td>
                    <span v-if="v.auic_is_pasien_setuju">
                      {{v.auic_is_pasien_setuju == 'Y'?'Ya':'Tidak'}}
                    </span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <a v-if="v.auic_file" :href="uploader(v.auic_file)" target="_blank"
                      class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700"
                      v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                    <span v-else> - </span>
                  </td>
                </tr>
                <tr v-if="!(dataTindakanRadiologi||[]).length">
                  <td colspan="99" class="text-center">Tidak ada Tindakan</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th colspan="4">Dokumen Inform Consent Radiologi</th>
                </tr>
                <tr>
                  <th>No</th>
                  <th>Nama Tindakan</th>
                  <th>Tindakan Disetujui Oleh Pasien</th>
                  <th>Dokumen</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v,k) in (dataInformedConsentPetugasRadiologi||[])" :key="k">
                  <td>{{k+1}}</td>
                  <td>{{v.auic_name}}</td>
                  <td>
                    <span v-if="v.auic_is_pasien_setuju">
                      {{v.auic_is_pasien_setuju == 'Y'?'Ya':'Tidak'}}
                    </span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <a v-if="v.auic_file" :href="uploader(v.auic_file)" target="_blank"
                      class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700"
                      v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                    <span v-else> - </span>
                  </td>
                </tr>
                <tr v-if="!(dataInformedConsentPetugasRadiologi||[]).length">
                  <td colspan="99" class="text-center">Tidak ada Tindakan</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </b-modal>



      <validation-observer ref="VFormInformconsent">
        <b-modal v-model="openModalIC" :title="'Tambahkan Informed Consent'" size="lg"
          @ok.prevent="submitInformConcent">
          <small class="text-primary mt-2">Klik pada salah satu kolom 'Isi Informasi' untuk melakukan perubahan.</small>
          <table class="table table-bordered table-sm table-input">
            <thead>
              <tr>
                <td colspan="3">
                  <div class="result_tab">
                    <h4>Nama Tindakan</h4>
                    <p>{{rowIC.auic_name}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <th width="24">No.</th>
                <th>Jenis Informasi</th>
                <th>Isi Informasi</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
                <td class="input-cell">
                  <textarea v-model="rowIC.auic_diagnosis" rows="2" placeholder="Informasi Terkait"
                    class="form-control"></textarea>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Dasar Diagnosis</td>
                <td class="input-cell">
                  <textarea v-model="rowIC.auic_dasar_diagnosis" rows="2" placeholder="Informasi Terkait"
                    class="form-control"></textarea>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Tindakan Kedokteran</td>
                <td class="input-cell">
                  <textarea v-model="rowIC.auic_tindakan_dokter" rows="2" placeholder="Informasi Terkait"
                    class="form-control"></textarea>
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>Indikasi Tindakan</td>
                <td class="input-cell">
                  <textarea v-model="rowIC.auic_indikasi_tindakan" rows="2" placeholder="Informasi Terkait"
                    class="form-control"></textarea>
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>Tata Cara</td>
                <td class="input-cell">
                  <textarea v-model="rowIC.auic_tata_cara" rows="2" placeholder="Informasi Terkait"
                    class="form-control"></textarea>
                </td>
              </tr>
              <tr>
                <td>6</td>
                <td>Tujuan</td>
                <td class="input-cell">
                  <textarea v-model="rowIC.auic_tujuan" rows="2" placeholder="Informasi Terkait"
                    class="form-control"></textarea>
                </td>
              </tr>
              <tr>
                <td>7</td>
                <td>Risiko</td>
                <td class="input-cell">
                  <textarea v-model="rowIC.auic_risiko" rows="2" placeholder="Informasi Terkait"
                    class="form-control"></textarea>
                </td>
              </tr>
              <tr>
                <td>8</td>
                <td>Komplikasi</td>
                <td class="input-cell">
                  <textarea v-model="rowIC.auic_kompilasi" rows="2" placeholder="Informasi Terkait"
                    class="form-control"></textarea>
                </td>
              </tr>
              <tr>
                <td>9</td>
                <td>Prognosis</td>
                <td class="input-cell">
                  <textarea v-model="rowIC.auic_prognosis" rows="2" placeholder="Informasi Terkait"
                    class="form-control"></textarea>
                </td>
              </tr>
              <tr>
                <td>10</td>
                <td>Alternatif & Risiko</td>
                <td class="input-cell">
                  <textarea v-model="rowIC.auic_alternatif" rows="2" placeholder="Informasi Terkait"
                    class="form-control"></textarea>
                </td>
              </tr>
              <tr>
                <td>11</td>
                <td>Lain-lain</td>
                <td class="input-cell">
                  <textarea v-model="rowIC.auic_lainnya" rows="2" placeholder="Informasi Terkait"
                    class="form-control"></textarea>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td width="50%" colspan="2">
                  <div>
                    <label for="">Apakah pasien/pendamping pasien menyetujui tindakan? <strong
                        class="text-danger">*</strong></label>
                    <div>
                      <b-form-radio-group :options="Config.mr.pasienSetuju" v-model="rowIC.auic_is_pasien_setuju" />
                      <VValidate :name="`Persetujuan Pasien`" v-model="rowIC.auic_is_pasien_setuju"
                        :rules="{required:1}" />
                    </div>
                  </div>
                </td>
                <td width="50%">
                  <div>
                    <label for="">Persetujuan tindakan diberikan kepada <strong class="text-danger">*</strong></label>
                    <div>
                      <b-form-radio-group :options="Config.mr.persetujuanTindakan"
                        v-model="rowIC.auic_persetujuan_tindakan" />
                      <VValidate :name="`Persetujuan Tindakan Kepada`" v-model="rowIC.auic_persetujuan_tindakan"
                        :rules="{required:1}" />
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </b-modal>
      </validation-observer>
    </div>


    <validation-observer ref="VFormModal">
      <b-modal v-model="openTemplateICModal" @ok.prevent="unduhtemplateIC(rowIC)" ok-title="Unduh Template"
        title="Unduh Template Dokumen" size="lg">
        <table class="table table-bordered table-sm text-uppercase">
          <tbody>
            <tr>
              <td colspan="3" class="bg-primary">Data Pasien</td>
            </tr>
            <tr>
              <td width="33%">
                <div class="result_tab">
                  <h4>Nama Pasien</h4>
                  <p>{{row.ap_fullname||"-"}}</p>
                </div>
              </td>
              <td width="33%">
                <div class="result_tab">
                  <h4>Usia</h4>
                  <p>{{row.ap_fullname||"-"}}</p>
                </div>
              </td>
              <td width="33%">
                <div class="result_tab">
                  <h4>Alamat</h4>
                  <p>{{row.ap_address||"-"}}</p>
                </div>
              </td>
            </tr>
            <template v-if="rowIC.auic_persetujuan_tindakan == 'PJ'">
              <tr>
                <td colspan="3" class="bg-primary">Data Penanggung Jawab</td>
              </tr>
              <tr>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Nama Penanggung Jawab</h4>
                    <b-form-input v-model="rowIC.auic_nama_pj" type="text" name="NamaPenanggung" id="NamaPenanggung"
                      class="form-control" placeholder="Nama Penanggung Jawab" />
                    <VValidate name="Nama Penanggung Jawab" v-model="rowIC.auic_nama_pj" :rules="{required: 1}" />
                  </div>
                </td>
                <td width="33%">
                    <div class="result_tab">
                    <h4>Usia Penanggung Jawab</h4>
                    <b-form-input v-model="rowIC.auic_usia_pj" type="text" name="UsiaPenanggung" id="UsiaPenanggung" class="form-control"
                    placeholder="Usia Penanggung Jawab"/>
                    <VValidate 
                        name="Usia Penanggung Jawab" 
                        v-model="rowIC.auic_usia_pj" 
                        :rules="{required: 1}"
                    />
                    </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Alamat Penanggung Jawab</h4>
                    <b-form-input v-model="rowIC.auic_address_pj" type="text" name="alamat" id="alamat"
                      class="form-control" placeholder="Alamat Penanggung Jawab" />
                    <VValidate name="Alamat Penanggung Jawab" v-model="rowIC.auic_address_pj" :rules="{required: 1}" />
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
  import GlobalVue from '@/libs/Global.vue'

  import DetailKajianAwal from '@/components/RiwayatPOLI/DetailKajianAwal.vue'
  import DetailKajianLanjutan from '@/components/RiwayatPOLI/DetailKajianLanjutan.vue'
  import RiwayatRM from '@/components/RiwayatPOLI/RiwayatRM.vue'


  import RiwayatRMUGD from '@/components/RiwayatUGD/RiwayatRMUGD.vue'
  import KajianPerawatUGD from '@/components/RiwayatUGD/KajianPerawatUGD.vue'


  import $ from 'jquery'
  const _ = global._
  import Gen from '@/libs/Gen.js'
  const moment = require('moment')


  export default {
    extends: GlobalVue,
    components: {
      DetailKajianAwal,
      DetailKajianLanjutan,
      RiwayatRM,
      KajianPerawatUGD,
      RiwayatRMUGD
    },
    data() {
      return {
        isBtnType: '',
        isShowBtn: false,
        isShowBtnLanjutan: false,
        rowReg: {},

        openDetailKajian: false,
        openDetailKajianPerawat: false,
        openRwiayatRM: false,
        dataRiwayatRMDokter: {},
        dataRiwayatRMDokterTerapi: {},
        dataRiwayatRMDokterGizi: {},
        dataRiwayatRMFisio: {},
        dataRiwayatRMRadiologi: {},
        dataRiwyatRMPerawat: {},
        dataRiwayatRMLab: {},
        dataRiwayatRMFarmasi: [],
        dataRiwayatRMFollowUp: {},

        dataRiwyatRMPerawatIS: '',

        statusElektroterapi: [],
        statusMekanoTerapi: [],
        statusLatihan: [],
        mAlatKesehatan: [],
        mRacikan: [],

        historyKajianPerawat: [],
        historyKajianDokter: [],
        historyKajianDokterTerapi: [],
        historyKajianDokterGizi: [],
        historyKajianFisio: [],
        historyKajianRadiologi: [],
        historyKajianLab: [],

        riwayatKeluhan: [],
        riwayatDiagnosa: [],
        riwayatObat: [],
        dataDokter: {},

        dataUGD: {},

        // new
        openReport: false,
        activeTabList: 1,

        openDetailKajianPerawatUGD: false,
        openRiwayatRMUGD: false,

        dataKajianPerawat: {},
        dataKajianDokter: {},

        historiKajianPerawat: [],
        historiKajianDokter: [],

        masterKajianPerawat: {},
        masterKajianDokter: {},

        masterTriase: {},
        dataKajianTriase: {},

        dataCatatanAsuhan: {},
        openCatatanAsuhan: false,

        historiKajianRadiologi: [],
        historiKajianLab: [],
        historiKajianTriase: [],

        dataKajianRadiologi: {},
        dataKajianLab: {},

        dataKajianFarmasi: [],
        dataUGDReservasi: {},

        openRadiologi: false,
        activeTabRadiologi: 1,

        dataInformedConsentRadiologi: [],
        dataTindakanRadiologi: [],
        dataInformedConsentPetugasRadiologi: [],
        rowIntraRS: {},
        openDokumenRadiologi: false,

        regID: null,
        openRiwayatData: {},
        namaPetugasRadiologi: null,
        namaPemberi: null,

        openModalIC: false,
        rowIC: {},

        openTemplateICModal: false,
        resepDokter: [],
      }
    },
    computed: {

      isHavePemeriksaanFisik() {
        let total = 0
        if (Object.keys(this.dataCatatanAsuhan || {}).length) {
          for (let i = 0; i < (this.Config.mr.configVitalSignUGD || []).length; i++) {
            if (this.dataCatatanAsuhan.rowDataDokter[this.Config.mr.configVitalSignUGD[i]['value']] != 'TP') {
              total += 1
            }
          }
        }
        return total
      },

      now() {
        return moment().format('DD MMM YYYY')
      },

      passToSubPerawatUGD() {
        return _.assign({
          isAdd: this.isAdd,
          mrValidation: this.mrValidation,
          Config: this.Config,
          row: this.row,
          rowReg: this.rowReg,
          dataKajianPerawat: this.dataKajianPerawat,
          historiKajianPerawat: this.historiKajianPerawat,
          masterKajianPerawat: this.masterKajianPerawat,
        })
      },

      passToSubRMUGD() {
        return _.assign({
          isAdd: this.isAdd,
          mrValidation: this.mrValidation,
          Config: this.Config,
          row: this.row,
          rowReg: this.rowReg,
          dataKajianDokter: this.dataKajianDokter,
          dataKajianTriase: this.dataKajianTriase,
          historiKajianDokter: this.historiKajianDokter,
          masterKajianDokter: this.masterKajianDokter,
          masterTriase: this.masterTriase,
          historiKajianRadiologi: this.historiKajianRadiologi,
          historiKajianLab: this.historiKajianLab,
          historiKajianTriase: this.historiKajianTriase,
          dataKajianRadiologi: this.dataKajianRadiologi,
          dataKajianLab: this.dataKajianLab,
          dataKajianFarmasi: this.dataKajianFarmasi,
          dataUGDReservasi: this.dataUGDReservasi
        })
      },


      showIntervensiCA() {
        if (Object.keys(this.dataCatatanAsuhan || {}).length) {
          if (this.dataCatatanAsuhan.dataKajianPerawat.aukprj_is_gangguan_jiwa == 'Y') {
            return this.dataCatatanAsuhan.dataKajianPerawat.aukprj_ed_value >= 90
          } else {
            if (this.row.ap_usia <= 18) {
              return this.dataCatatanAsuhan.dataKajianPerawat.aukprj_morshe_value >= 12
            } else if (this.row.ap_usia > 18 && this.row.ap_usia <= 60) {
              return this.dataCatatanAsuhan.dataKajianPerawat.aukprj_morshe_value >= 25
            } else if (this.row.ap_usia > 60) {
              return this.getTotalSydney >= 6
            } else {
              return false
            }
          }
        }
        return false
      },
      getTotalSydneyCA() {
        let total = 0
        if (Object.keys(this.dataCatatanAsuhan || {}).length) {
          if (this.dataCatatanAsuhan.dataKajianPerawat.aukprj_riwayat_jatuh_is_jatuh == "Y" || this.dataCatatanAsuhan
            .dataKajianPerawat.aukprj_riwayat_jatuh_is_jatuh2bulan == "Y") {
            total = total + 6
          }

          if (this.dataCatatanAsuhan.dataKajianPerawat.aukprj_status_mental_dellirium == "Y" || this.dataCatatanAsuhan
            .dataKajianPerawat.aukprj_status_mental_disorientasi == "Y" ||
            this.dataCatatanAsuhan.dataKajianPerawat.aukprj_status_mental_agitasi == "Y") {
            total = total + 14
          }

          if (this.dataCatatanAsuhan.dataKajianPerawat.aukprj_penglihatan_kacamata == "Y" || this.dataCatatanAsuhan
            .dataKajianPerawat.aukprj_penglihatan_buram == "Y" ||
            this.dataCatatanAsuhan.dataKajianPerawat.aukprj_penglihatan_glaucoma == "Y") {
            total = total + 1
          }

          if (this.dataCatatanAsuhan.dataKajianPerawat.aukprj_kebiasaan == "Y") {
            total = total + 2
          }

          if (this.dataCatatanAsuhan.dataKajianPerawat.aukprj_transfer + this.dataCatatanAsuhan.dataKajianPerawat
            .aukprj_mobilitas > 3) {
            total = total + 7
          }
        }
        return total
      },



      isPenunjang() {
        if (this.user.levelId == 9 || this.user.levelId == 13 || this.user.levelId == 12 || this.user.levelId == 6) {
          return true
        } else {
          return false
        }
      },
      isDev() {
        return process.env.VUE_APP_IS_DEV || false
      },
      isList() {
        return !this.$route.params.rmNo
      },
      typeKajian() {
        return this.$route.params.typeKajian
      },
      dataUGDList() {
        return this.dataUGD.data
      },
      passToSub() {
        return _.assign({
          isAdd: this.isAdd,
          mrValidation: this.mrValidation,
          Config: this.Config,
          row: this.row,
          rowReg: this.rowReg,
          mPekerjaan: this.mPekerjaan,
          mAlatBantu: this.mAlatBantu,
          mSkrininGizi: this.mSkrininGizi,
          mSkrininGiziV2: this.mSkrininGiziV2,
          diagnosa: this.diagnosa,
          mSDKI: this.mSDKI,
          mSLKI: this.mSLKI,
          mSIKI: this.mSIKI,
          mPoli: this.mPoli,
          mDokter: this.mDokter,
          mKesadaran: this.mKesadaran,
          historyKajianPerawat: this.historyKajianPerawat,
          resepDokter: this.resepDokter,
          mAlergi: this.mAlergi,
        })
      },
      passToSubLanjutan() {
        return _.assign({
          isAdd: this.isAdd,
          mrValidation: this.mrValidation,
          Config: this.Config,
          row: this.row,
          rowReg: this.rowReg,
          diagnosa: this.diagnosa,
          mSDKI: this.mSDKI,
          mSIKI: this.mSIKI,
          mKesadaran: this.mKesadaran,
          historyKajianPerawat: this.historyKajianPerawat,
          resepDokter: this.resepDokter,
          mAlergi: this.mAlergi,
        })
      },

      passToSubRM() {
        return _.assign({
          dataRiwayatRMDokter: this.dataRiwayatRMDokter,
          dataRiwayatRMDokterTerapi: this.dataRiwayatRMDokterTerapi,
          dataRiwayatRMDokterGizi: this.dataRiwayatRMDokterGizi,
          dataRiwayatRMFisio: this.dataRiwayatRMFisio,
          dataRiwayatRMRadiologi: this.dataRiwayatRMRadiologi,
          dataRiwayatRMLab: this.dataRiwayatRMLab,
          dataRiwayatRMFarmasi: this.dataRiwayatRMFarmasi,
          row: this.row,
          rowReg: this.rowReg,
          dataRiwayatRMFollowUp: this.dataRiwayatRMFollowUp,
          historyKajianPerawat: this.historyKajianPerawat,
          historyKajianDokter: this.historyKajianDokter,
          historyKajianDokterTerapi: this.historyKajianDokterTerapi,
          historyKajianDokterGizi: this.historyKajianDokterGizi,
          historyKajianFisio: this.historyKajianFisio,
          historyKajianRadiologi: this.historyKajianRadiologi,
          historyKajianLab: this.historyKajianLab,


        })
      },

      selectedLabInput() {
        let data = []
        for (let ik = 0; ik < (this.dataCatatanAsuhan.rowDataDokter.aupdl_data || []).length; ik++) {
          for (let jk = 0; jk < (this.dataCatatanAsuhan.rowDataDokter.aupdl_data[ik]['dubData'] || []).length; jk++) {
            for (let kk = 0; kk < (this.dataCatatanAsuhan.rowDataDokter.aupdl_data[ik]['dubData'][jk]['data'] || [])
              .length; kk++) {
              if (this.dataCatatanAsuhan.rowDataDokter.aupdl_data[ik]['dubData'][jk]['data'][kk]['selected']) {
                data.push(this.dataCatatanAsuhan.rowDataDokter.aupdl_data[ik]['dubData'][jk]['data'][kk]['text'])
              }
            }
          }
        }
        if (this.dataCatatanAsuhan.rowDataDokter.aupdl_hasil_lainnya) {
          data.push(this.dataCatatanAsuhan.rowDataDokter.aupdl_hasil_lainnya)
        }
        return data.join(", ")
      },

      selectedRadioInput() {
        let data = []
        for (let i = 0; i < (this.dataCatatanAsuhan.rowDataDokter.aupdr_data || []).length; i++) {
          for (let j = 0; j < (this.dataCatatanAsuhan.rowDataDokter.aupdr_data[i]['data'] || []).length; j++) {
            if (this.dataCatatanAsuhan.rowDataDokter.aupdr_data[i]['data'][j]['selected']) {
              data.push(this.dataCatatanAsuhan.rowDataDokter.aupdr_data[i]['data'][j]['text'])
            }
          }
        }
        if (this.dataCatatanAsuhan.rowDataDokter.aupdr_hasil_lainnya) {
          data.push(this.dataCatatanAsuhan.rowDataDokter.aupdr_hasil_lainnya)
        }
        return data.join(", ")
      },

    },
    mounted() {
      setTimeout(() => {
        this.initSticky()
      }, 1000)
      this.apiGet()
    },
    methods: {
      assetLocal(img) {
        let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL + "/" : "/"
        img = def + img
        return img
      },
      getConfigDynamic(master, value) {
        let text = ''
        if (value != null) {
          let index = (master || []).findIndex(x => x.value == value)
          if (index !== -1) {
            text = master[index]['text']
          }
        }
        return text
      },
      addInformConsent(v) {
        this.openModalIC = true
        this.rowIC = {
          auic_is_pasien_setuju: null,
          auic_persetujuan_tindakan: null,
          auic_name: v.text + " (" + this.getConfigDynamic(this.Config.mr.KategoriRadiologi, v.jenis) + ")",
          auic_status: v.auic_status,
          auic_id: v.auic_id,
          auic_parent_id: v.auic_id,
          auic_tindakan_radiologi: v.id,
          typePost: 'add'
        }
      },
      editInformConsent(v) {
        this.openModalIC = true
        this.rowIC = v
        this.rowIC.typePost = 'update'
      },
      removeInformConsent(v) {
        this.loadingOverlay = true
        let data = {
          type: "remove-ic",
          id: v.auic_id
        }
        Gen.apiRest(
          "/do/" + 'UGDFormDokter', {
            data: data
          }, "POST"
        ).then(() => {
          this.loadingOverlay = false
          this.apiGet()
          return this.$swal({
            title: "Data Informed Consent berhasil dihapus",
            icon: 'success',
            confirmButtonText: 'Ok'
          })
        })
      },
      submitInformConcent() {
        this.$refs['VFormInformconsent'].validate().then(success => {
          if (success) {
            let data = this.rowIC
            data.type = 'submit-data-inform-consent-petugas-radiologi'
            data.auic_aur_id = this.rowReg.aur_id
            data.auic_name = this.rowIC.auic_name
            data.auic_parent_id = this.rowIC.auic_id

            this.loadingOverlay = true
            Gen.apiRest(
              "/do/" + 'UGDFormRadiologi', {
                data: data
              },
              "POST"
            ).then(res => {
              this.apiGet()
              this.openModalIC = false

              this.rowIC.auic_id = null

              return this.$swal({
                title: "Data Informed Consent berhasil disimpan",
                icon: 'success',
                confirmButtonText: 'Ok'
              })
            })
          }
        })
      },

      toCatatanAsuhan(regId) {
        this.$router.push({
          name: 'UGDCatatanAsuhan',
          params: {
            pageSlug: regId
          }
        }).catch(() => {})
      },
      toCatatanAsuhanDetail(regId) {
        this.loadingOverlay = true
        Gen.apiRest(
          "/get/" + "UGDCatatanAsuhan" + "/" + regId,
        ).then(res => {
          this.loadingOverlay = false
          this.openCatatanAsuhan = true
          this.dataCatatanAsuhan = res.data
          this.regID = regId
        })
      },
      toDetailKajianPerawatUGD(v, regID) {
        this.loadingOverlay = true
        Gen.apiRest(
          "/get/" + 'UGDFormPerawat' + '/' + this.row.ap_id + '/' + v.kajianPerawat + '?regId=' + v.aurm_aur_id,
        ).then(res => {
          this.openDetailKajianPerawatUGD = true
          this.dataKajianPerawat = res.data
          this.loadingOverlay = false
        })

        Gen.apiRest(
          "/get/" + 'UGDFormPerawat' + '/' + this.row.ap_id + '/' + v.kajianPerawat + '?regId=' + v.aurm_aur_id +
          '&master=1',
        ).then(res => {
          this.masterKajianPerawat = res.data
          console.log(this.masterKajianPerawat)
        })
      },
      doOpenRiwayatRMUGD(v) {
        this.loadingOverlay = true

        Gen.apiRest(
          "/get/" + 'UGDFormDokter' + '/' + this.row.ap_id + '/' + v.kajianDokter + '?regId=' + v.aurm_aur_id,
        ).then(res => {
          this.openRiwayatRMUGD = true
          this.dataKajianDokter = res.data
          this.loadingOverlay = false
          this.openRiwayatData = v
        })

        Gen.apiRest(
          "/get/" + 'UGDFormDokter' + '/' + this.row.ap_id + '/' + v.kajianDokter + '?regId=' + v.aurm_aur_id +
          '&master=1',
        ).then(res => {
          this.masterKajianDokter = res.data
        })


        Gen.apiRest(
          "/get/" + 'UGDTriase' + '/' + v.triase_id + '?regId=' + v.aurm_aur_id,
        ).then(res => {
          this.dataKajianTriase = res.data
        })

        Gen.apiRest(
          "/get/" + 'UGDTriase' + '/' + 'master',
        ).then(res => {
          this.masterTriase = res.data
        })

        if (v.kajianLab) {
          Gen.apiRest(
            "/get/" + 'UGDFormLab' + '/' + this.row.ap_id + '/' + v.kajianLab + '?regId=' + v.aurm_aur_id,
          ).then(res => {
            this.dataKajianLab = res.data
          })
        }

        if (v.kajianRadiologi) {
          Gen.apiRest(
            "/get/" + 'UGDFormRadiologi' + '/' + this.row.ap_id + '/' + v.kajianRadiologi + '?regId=' + v.aurm_aur_id,
          ).then(res => {
            this.dataKajianRadiologi = res.data
          })
        }


        Gen.apiRest(
          "/do/" + 'UGDAssesment', {
            data: {
              type: 'get-riwayat-rekam-medis',
              aur_id: v.aurm_aur_id
            }
          }
        ).then(res => {
          let resp = res.data
          this.historiKajianPerawat = resp.historyKajianPerawat
          this.historiKajianDokter = resp.historyKajianDokter
          this.historiKajianRadiologi = resp.historyKajianRadiologi
          this.historiKajianLab = resp.historyKajianLab
          this.historiKajianTriase = resp.historyKajianTriase
          this.loadingOverlay = false
        })


        Gen.apiRest(
          "/do/" + 'UGDAssesment', {
            data: {
              type: 'get-obat-farmasi',
              regId: v.aurm_aur_id
            }
          }
        ).then(res => {
          let resp = res.data
          this.dataKajianFarmasi = resp.data
          this.dataUGDReservasi = resp.dataUGDReservasi
          this.loadingOverlay = false
        })


        this.openRiwayatRMUGD = true
      },
      changeTab(val) {
        this.activeTabList = val
      },
      back() {
        this.$router.back()
      },
      deleteRiwayatRM(v) {
        this.loadingOverlay = true
        Gen.apiRest(
          "/do/" + 'UGDAssesment', {
            data: {
              type: 'delete-data-rm',
              aurm_id: v.aurm_id
            }
          }
        ).then(res => {
          this.loadingOverlay = false
          this.$swal({
            title: `Data Berhasil DiHapus`,
            icon: 'success',
          })
          this.apiGet()
        })
      },
      initSticky() {
        const PageHeaderSticky = document.querySelector('.page-header-sticky')
        if (PageHeaderSticky) {
          const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
          if ($(".page-header-sticky").length && $(".page-header-sticky-toggle").length) {
            PageHeaderToggle.addEventListener('click', function (e) {
              // e.preventDefault()
              PageHeaderSticky.classList.toggle('page-header-sticky-open')
              if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                PageHeaderToggle.querySelector('span').innerText = 'Sembunyikan Informasi Pasien'
                PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
              } else {
                PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
              }
            })
          }
        }
      },

      doOpenRiwayatDokter(v) {
        Gen.apiRest(
          "/do/" + 'UGDAssesment', {
            data: {
              type: 'get-history-kajian-dokter',
              regId: v
            }
          }
        ).then(res => {
          let resp = res.data
          this.doOpenRiwayatRM(resp.data)
        })
      },

      doOpenRiwayatRM(v) {
        this.loadingOverlay = true
        if (v.kajianDokter) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianDokter + '?regId=' + v.arm_ar_id,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMDokter = res.data
            this.dataRiwayatRMDokter.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMDokter = {}
        }

        if (v.kajianDokterTerapi) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianDokterTerapi + '?regId=' + v.arm_ar_id,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMDokterTerapi = res.data
            this.dataRiwayatRMDokterTerapi.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMDokterTerapi = {}
        }

        if (v.kajianGizi) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianGizi + '?regId=' + v.arm_ar_id,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMDokterGizi = res.data
            this.dataRiwayatRMDokterGizi.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMDokterGizi = {}
        }

        if (v.kajianFisio) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianFisio + '?regId=' + v.arm_ar_id,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMFisio = res.data
            this.dataRiwayatRMFisio.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMFisio = {}
        }

        if (v.kajianRadiologi) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianRadiologi + '?regId=' + v.arm_ar_id,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMRadiologi = res.data
            this.dataRiwayatRMRadiologi.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMRadiologi = {}
        }

        if (v.kajianLab) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianLab + '?regId=' + v.arm_ar_id,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMLab = res.data
            this.dataRiwayatRMLab.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMLab = {}
        }

        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: {
              type: 'get-obat-farmasi',
              regId: v.arm_ar_id
            }
          }
        ).then(res => {
          let resp = res.data
          this.dataRiwayatRMFarmasi = resp.data
          this.loadingOverlay = false
        })

        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: {
              type: 'get-riwayat-rekam-medis',
              ar_id: v.arm_ar_id
            }
          }
        ).then(res => {
          let resp = res.data

          this.historyKajianPerawat = resp.historyKajianPerawat
          this.historyKajianDokter = resp.historyKajianDokter
          this.historyKajianDokterTerapi = resp.historyKajianDokterTerapi
          this.historyKajianDokterGizi = resp.historyKajianDokterGizi
          this.historyKajianFisio = resp.historyKajianFisio
          this.historyKajianRadiologi = resp.historyKajianRadiologi
          this.historyKajianLab = resp.historyKajianLab

          this.loadingOverlay = false
        })


        Gen.apiRest(
          "/get/" + 'FollowUp' + '/' + this.row.ap_id + '?regId=' + v.arm_ar_id,
        ).then(res => {
          this.loadingOverlay = false
          this.dataRiwayatRMFollowUp = res.data
          this.dataRiwayatRMFollowUp.Config = this.Config
          this.openRwiayatRM = true
        })



        setTimeout(() => {
          this.loadingOverlay = false
          this.openRwiayatRM = true
        }, 2000)
      },

      viewKajianPerawat(id = null) {
        this.loadingOverlay = true
        if (!id) {
          id = this.row.kajianPerawat
        }

        Gen.apiRest(
          "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + id + '?regId=' + this.$route.query.regId,
        ).then(res => {
          this.loadingOverlay = false
          this.dataRiwyatRMPerawat = res.data
          this.dataRiwyatRMPerawat.Config = this.Config
          this.dataRiwyatRMPerawat.row['isDok'] = true
          this.dataRiwyatRMPerawatIS = res.data.row.arm_is_kajian_awal
          this.openDetailKajianPerawat = true
        })
      },

      toDetail(v, ar_id) {
        this.loadingOverlay = true
        Gen.apiRest(
          "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianPerawatId + '?regId=' + ar_id,
        ).then(res => {
          this.loadingOverlay = false
          this.openDetailKajian = true
          _.forEach(res.data, (v, k) => {
            this.$set(this, k, v)
          })
        })
      },

      doRefreshData() {
        this.apiGet()
        window.scrollTo(0, 0)
      },

      otherConditional(v) {
        return v.arm_created_by == this.user.id && moment().format('YYYY-MM-DD') <= this.to24Hours(v.arm_created_date)
      },

      otherConditionalUGD(v) {
        return (moment().format('YYYY-MM-DD') <= this.to24Hours(v.aurm_created_date) || (v.aurm_is_approve == 'Y') || (!
          v.aurm_id))
      },
      to24Hours(date) {
        return moment(date).add(48, 'hours').format('YYYY-MM-DD')
      },
      apiGet(params = {}, page = 1) {

        if (!this.pageSlug || !this.$route.query.regId) {
          this.$router.push({
            name: 'Dashboard'
          }).catch(() => {})
        }

        if (this.pageSlug || this.$route.name == 'Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.$route.params.rmNo ? 'UGDAssesment' + '/' + this.$route.params.pageSlug + '/' + this.$route
          .params.rmNo : 'UGDAssesment' + '/' + this.$route.params.pageSlug + '?byPassLevel=23'

        Gen.apiRest(
          "/get/" + url, {
            params: Object.assign({
              page: page
            }, paramsQuery, params.query || {})
          }
        ).then(res => {
          this.loadingOverlay = false
          _.forEach(res.data, (v, k) => {
            this.$set(this, k, v)
          })
          this.pageNow = page
          this.last_page = this.data.last_page

          if (!this.isFound) {
            this.$router.push({
              name: 'Dashboard'
            }).catch(() => {})
          }
        }).catch(() => {
          this.loadingOverlay = false
        })

        // master
        if (this.$route.params.rmNo) {
          Gen.apiRest(
            "/get/" + url + '?master=1', {
              params: Object.assign({
                page: page
              }, paramsQuery, params.query || {})
            }
          ).then(res => {
            _.forEach(res.data, (v, k) => {
              this.$set(this, k, v)
            })
          })
        }

      },
      loadMore() {
        this.pageNow = this.pageNow + 1
        this.loadingOverlay = true

        let url = this.$route.params.rmNo ? 'UGDAssesment' + '/' + this.$route.params.pageSlug + '/' + this.$route
          .params.rmNo : 'UGDAssesment' + '/' + this.$route.params.pageSlug

        let paramsQuery = this.$route.query

        Gen.apiRest(
          "/get/" + url, {
            params: Object.assign({
              page: this.pageNow
            }, paramsQuery)
          }
        ).then(res => {
          this.loadingOverlay = false
          let newData = res.data.data.data
          for (let i = 0; i < (newData || []).length; i++) {
            this.data.data.push(newData[i])
          }
        }).catch(() => {
          this.loadingOverlay = false
        })
      },
      loadMoreUGD() {
        this.pageNow = this.pageNow + 1
        this.loadingOverlay = true

        let url = this.$route.params.rmNo ? 'UGDAssesment' + '/' + this.$route.params.pageSlug + '/' + this.$route
          .params.rmNo : 'UGDAssesment' + '/' + this.$route.params.pageSlug

        let paramsQuery = this.$route.query

        Gen.apiRest(
          "/get/" + url, {
            params: Object.assign({
              page: this.pageNow
            }, paramsQuery)
          }
        ).then(res => {
          this.loadingOverlay = false
          let newData = res.data.data.data
          for (let i = 0; i < (newData || []).length; i++) {
            this.data.data.push(newData[i])
          }
        }).catch(() => {
          this.loadingOverlay = false
        })
      },
      redirectUGD(v) {
        console.log(v)
        if (this.user.levelId == this.uPerawatUGD || this.$route.query.byPassLevel == this.uPerawatUGD) {
          if (this.$route.query.byPassLevel) {
            this.$router.push({
              name: 'UGDFormPerawat',
              params: {
                pageSlug: this.row.ap_id,
                rmNo: v.aurm_id
              },
              query: {
                regId: this.$route.query.regId,
                byPassLevel: this.$route.query.byPassLevel
              }
            }).catch(() => {})
          } else {
            this.$router.push({
              name: 'UGDFormPerawat',
              params: {
                pageSlug: this.row.ap_id,
                rmNo: v.aurm_id
              },
              query: {
                regId: this.$route.query.regId
              }
            }).catch(() => {})
          }
        } else if (this.user.levelId == this.uDokterUGD || this.$route.query.byPassLevel == this.uDokterUGD) {
          // ugdDokter
          if (this.$route.query.byPassLevel) {
            this.$router.push({
              name: 'UGDFormDokter',
              params: {
                pageSlug: this.row.ap_id,
                rmNo: v.aurm_id
              },
              query: {
                regId: this.$route.query.regId,
                byPassLevel: this.$route.query.byPassLevel
              }
            }).catch(() => {})
          } else {
            this.$router.push({
              name: 'UGDFormDokter',
              params: {
                pageSlug: this.row.ap_id,
                rmNo: v.aurm_id
              },
              query: {
                regId: this.$route.query.regId
              }
            }).catch(() => {})
          }
        } else if (this.user.levelId == this.uLab || this.$route.query.byPassLevel == this.uLab) {
          // ugdDokter
          if (this.$route.query.byPassLevel) {
            this.$router.push({
              name: 'UGDFormLab',
              params: {
                pageSlug: this.row.ap_id,
                rmNo: v.aurm_id
              },
              query: {
                regId: this.$route.query.regId,
                byPassLevel: this.$route.query.byPassLevel
              }
            }).catch(() => {})
          } else {
            this.$router.push({
              name: 'UGDFormLab',
              params: {
                pageSlug: this.row.ap_id,
                rmNo: v.aurm_id
              },
              query: {
                regId: this.$route.query.regId
              }
            }).catch(() => {})
          }
        } else if (this.user.levelId == this.uRadiologi || this.$route.query.byPassLevel == this.uRadiologi) {
          // ugdDokter
          if (this.$route.query.byPassLevel) {
            this.$router.push({
              name: 'UGDFormRadiologi',
              params: {
                pageSlug: this.row.ap_id,
                rmNo: v.aurm_id
              },
              query: {
                regId: this.$route.query.regId,
                byPassLevel: this.$route.query.byPassLevel
              }
            }).catch(() => {})
          } else {
            this.$router.push({
              name: 'UGDFormRadiologi',
              params: {
                pageSlug: this.row.ap_id,
                rmNo: v.aurm_id
              },
              query: {
                regId: this.$route.query.regId
              }
            }).catch(() => {})
          }
        }
      },
      redirect(v) {
        if (v.arm_status == this.sPerawat) {
          this.$router.push({
            name: 'RekamMedis',
            params: {
              pageSlug: this.row.ap_id,
              rmNo: v.arm_id,
              typeKajian: v.arm_is_kajian_awal == 'Y' ? 'awal' : 'lanjutan'
            },
            query: {
              regId: v.arm_ar_id
            }
          }).catch(() => {})
        } else if (v.arm_status == this.sDokter) {
          this.$router.push({
            name: 'RekamMedis',
            params: {
              pageSlug: this.row.ap_id,
              rmNo: v.arm_id,
              typeKajian: 'kajian-dokter'
            },
            query: {
              regId: v.arm_ar_id
            }
          }).catch(() => {})
        } else if (v.arm_status == this.sFisio) {
          this.$router.push({
            name: 'RekamMedis',
            params: {
              pageSlug: this.row.ap_id,
              rmNo: v.arm_id,
              typeKajian: 'kajian-dokter-terapi'
            },
            query: {
              regId: v.arm_ar_id
            }
          }).catch(() => {})
        } else if ((v.arm_status == this.sPPA && this.user.levelId == 6) || (v.arm_status == this.sPPA && this.$route
            .query.byPassLevel == 6)) {
          this.$router.push({
            name: 'RekamMedis',
            params: {
              pageSlug: this.row.ap_id,
              rmNo: v.arm_id,
              typeKajian: 'kajian-gizi'
            },
            query: {
              regId: v.arm_ar_id
            }
          }).catch(() => {})
        } else if ((v.arm_status == this.sPPA && this.user.levelId == 9) || (v.arm_status == this.sPPA && this.$route
            .query.byPassLevel == 9)) {
          this.$router.push({
            name: 'RekamMedis',
            params: {
              pageSlug: this.row.ap_id,
              rmNo: v.arm_id,
              typeKajian: v.arm_is_kajian_awal == 'Y' ? 'kajian-awal-fisio' : 'kajian-lanjutan-fisio'
            },
            query: {
              regId: v.arm_ar_id
            }
          }).catch(() => {})
        } else if ((v.arm_status == this.sPPA && this.user.levelId == 13) || (v.arm_status == this.sPPA && this.$route
            .query.byPassLevel == 13)) {
          this.$router.push({
            name: 'RekamMedis',
            params: {
              pageSlug: this.row.ap_id,
              rmNo: v.arm_id,
              typeKajian: 'kajian-radiologi'
            },
            query: {
              regId: v.arm_ar_id
            }
          }).catch(() => {})
        } else if ((v.arm_status == this.sPPA && this.user.levelId == 12) || (v.arm_status == this.sPPA && this.$route
            .query.byPassLevel == 12)) {
          this.$router.push({
            name: 'RekamMedis',
            params: {
              pageSlug: this.row.ap_id,
              rmNo: v.arm_id,
              typeKajian: 'kajian-lab'
            },
            query: {
              regId: v.arm_ar_id
            }
          }).catch(() => {})
        }
      },
      downloadReport(link, name) {
        let data = {
          exptype: 'pdf',
          type: "export",
          pasienId: this.$route.params.pageSlug,
          regId: this.$route.query.regId
        }
        let self = this

        $.ajax({
          type: "POST",
          url: process.env.VUE_APP_API_URL + `/report/${link}?token=IXs1029102asoaksoas102901290`,
          data: data,
          cache: false,
          xhrFields: {
            responseType: 'blob'
          },
          success: data => {
            self.loadingOverlay = false
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(data)
            link.download = `${name}-${moment().format("YYYY-MM-DD")}.pdf`
            link.click()
          },
          fail: data => {
            self.loadingOverlay = false
            alert('Not downloaded')
          }
        })
      },
      toKajian() {
        this.loadingOverlay = true
        if (this.user.levelId == this.uPerawatUGD || this.$route.query.byPassLevel == this.uPerawatUGD) {
          Gen.apiRest(
            "/do/" + 'UGDFormPerawat', {
              data: {
                type: 'init-kajian',
                idPasien: this.$route.params.pageSlug,
                regId: this.$route.query.regId,
                dokter_id: this.rowReg.aur_dokter_id,
                poli_id: this.rowReg.aur_mpo_id
              }
            },
            "POST"
          ).then(res => {
            this.loadingOverlay = false
            this.$router.push({
              name: 'UGDFormPerawat',
              params: {
                pageSlug: this.row.ap_id,
                rmNo: res.data.aurm_id
              },
              query: {
                regId: this.$route.query.regId
              }
            }).catch(() => {})
          })
        } else if (this.user.levelId == this.uDokterUGD || this.$route.query.byPassLevel == this.uDokterUGD) {
          Gen.apiRest(
            "/do/" + 'UGDFormDokter', {
              data: {
                type: 'init-kajian',
                idPasien: this.$route.params.pageSlug,
                regId: this.$route.query.regId,
                dokter_id: this.rowReg.aur_dokter_id,
                poli_id: this.rowReg.ar_mpo_id
              }
            },
            "POST"
          ).then(res => {
            this.loadingOverlay = false
            this.$router.push({
              name: 'UGDFormDokter',
              params: {
                pageSlug: this.row.ap_id,
                rmNo: res.data.aurm_id
              },
              query: {
                regId: this.$route.query.regId
              }
            }).catch(() => {})
          })
        } else if (this.user.levelId == this.uLab || this.$route.query.byPassLevel == this.uLab) {
          Gen.apiRest(
            "/do/" + 'UGDFormLab', {
              data: {
                type: 'init-kajian',
                idPasien: this.$route.params.pageSlug,
                regId: this.$route.query.regId,
                dokter_id: this.rowReg.aur_dokter_id,
                poli_id: this.rowReg.aur_mpo_id
              }
            },
            "POST"
          ).then(res => {
            this.loadingOverlay = false
            this.$router.push({
              name: 'UGDFormLab',
              params: {
                pageSlug: this.row.ap_id,
                rmNo: res.data.aurm_id
              },
              query: {
                regId: this.$route.query.regId
              }
            }).catch(() => {})
          })
        } else if (this.user.levelId == this.uRadiologi || this.$route.query.byPassLevel == this.uRadiologi) {
          this.loadingOverlay = false

          Gen.apiRest('/do/' + 'UGDTindakLanjut', {
            data: {
              type: 'get-data-intra-rs',
              id: this.$route.query.regId,
            }
          }, 'POST').then(res => {
            let resp = res.data
            this.rowIntraRS = resp.rowIntraRS
            this.openRadiologi = true
            setTimeout(() => {
              $(".sign-container canvas").attr("width", 400)
              $(".sign-container canvas").attr("height", 160)
            }, 200)
            this.loadingOverlay = false
          })

          // Gen.apiRest(
          //     "/do/"+'UGDFormRadiologi',
          //     {data:{type:'init-kajian',idPasien: this.$route.params.pageSlug, regId: this.$route.query.regId, dokter_id: this.rowReg.aur_dokter_id, poli_id: this.rowReg.aur_mpo_id}}, 
          //     "POST"
          // ).then(res=>{
          //     this.loadingOverlay = false
          //     this.$router.push({ name: 'UGDFormRadiologi', params: { pageSlug: this.row.ap_id, rmNo: res.data.aurm_id}, query: {regId: this.$route.query.regId} }).catch(()=>{})
          // })
        }

      },
      submitRadiologi() {

        this.rowReg.aur_radiologi_template = $(".document-framer").html()

        this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan menyimpan data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        }).then(result => {
          if (result.value) {

            let data = this.rowReg
            data.type = 'submit-data-radiologi-ttd'
            data.dataTindakanRadiologi = this.dataTindakanRadiologi
            data.dataInformedConsentPetugasRadiologi = this.dataInformedConsentPetugasRadiologi

            this.loadingOverlay = true
            Gen.apiRest(
              "/do/" + 'UGDAssesment', {
                data: data
              },
              "POST"
            ).then(resp1 => {
              Gen.apiRest(
                "/do/" + 'UGDFormRadiologi', {
                  data: {
                    type: 'init-kajian',
                    idPasien: this.$route.params.pageSlug,
                    regId: this.$route.query.regId,
                    dokter_id: this.rowReg.aur_dokter_id,
                    poli_id: this.rowReg.aur_mpo_id
                  }
                },
                "POST"
              ).then(res => {
                this.loadingOverlay = false
                this.openRadiologi = false
                if (resp1.data.message == 'Tindakan Radiologi Pasien Tidak Disetujui oleh Pasien') {
                  this.apiGet()
                } else {
                  this.$router.push({
                    name: 'UGDFormRadiologi',
                    params: {
                      pageSlug: this.row.ap_id,
                      rmNo: res.data.aurm_id
                    },
                    query: {
                      regId: this.$route.query.regId
                    }
                  }).catch(() => {})
                }
              })
            }).catch(err => {
              this.loadingOverlay = false
              if (err) {
                err.statusType = err.status
                err.status = "error"
                err.title = err.response?.data?.title
                err.message = err.response?.data?.message
                err.messageError = err.message
              }
              this.doSetAlertForm(err)
            })
          }
        })

      },
      lihatDokumen(v) {
        this.openDokumenRadiologi = true
      },
      changeTabRadiologi(val) {
        this.activeTabRadiologi = val
        if (val == 2) {
          if (this.rowReg.aur_radiologi_ttd) {
            if (this.$refs['ttdRadiologi']) {
              this.$refs['ttdRadiologi'].fromDataURL(this.rowReg.aur_radiologi_ttd)
            }
          }
          if (this.rowReg.aur_radiologi_pemberi_ttd) {
            if (this.$refs['ttdPemberi']) {
              this.$refs['ttdPemberi'].fromDataURL(this.rowReg.aur_radiologi_pemberi_ttd)
            }
          }

        }
      },

      undoTTD(refs, field) {
        this.$refs[refs].clearSignature()
        this.rowReg[field] = null
      },
      output(refs, field) {
        let {
          isEmpty,
          data
        } = this.$refs[refs].saveSignature()
        if (!isEmpty) {
          this.rowReg[field] = data
        }
      },
      downloadFileIntra(id, val) {
        let data = {
          exptype: 'xlsx',
          type: "export",
          id: id
        }
        let self = this
        $.ajax({
          type: "POST",
          url: process.env.VUE_APP_API_URL + `/dokumen-ttd/${val}?token=IXs1029102asoaksoas102901290`,
          data: data,
          cache: false,
          xhrFields: {
            responseType: 'blob'
          },
          success: data => {
            self.loadingOverlay = false
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(data)
            link.download = `${val}-${moment().format("YYYY-MM-DD")}.pdf`
            link.click()
          },
          fail: data => {
            self.loadingOverlay = false
            alert('Not downloaded')
          }
        })
      },

      uploadDok(v) {
        Gen.apiRest('/do/' + 'UGDTindakLanjut', {
          data: {
            type: 'update-data-ic',
            id: v.auic_id,
            auic_file: v.auic_file,
            auic_nama_pj: v.auic_nama_pj,
            auic_usia_pj: v.auic_usia_pj,
            auic_address_pj: v.auic_address_pj,
          }
        }, 'POST').then(res => {
          this.apiGet()
        })
      },

      openTemplateIC(v) {
        this.rowIC = v
        this.rowIC.auic_nama_pj = this.row.arpj_fullname
        this.rowIC.auic_address_pj = this.row.arpj_address
        this.rowIC.auic_usia_pj = this.rowIC.auic_usia_pj||this.row.ap_usia_with_ket
        this.openTemplateICModal = true
      },
      unduhtemplateIC(v) {
        this.$refs['VFormModal'].validate().then(success => {
          if (success) {
            this.uploadDok(v)
            setTimeout(() => {
              this.openTemplateICModal = false
              let data = {
                exptype: 'xlsx',
                type: "export",
                id: v.auic_id,
                regID: this.rowReg.aur_id
              }
              let self = this
              self.loadingOverlay = true
              $.ajax({
                type: "POST",
                url: process.env.VUE_APP_API_URL +
                  `/dokumen-ttd/${'InformConsent'}?token=IXs1029102asoaksoas102901290`,
                data: data,
                cache: false,
                xhrFields: {
                  responseType: 'blob'
                },
                success: data => {
                  self.loadingOverlay = false
                  var link = document.createElement('a')
                  link.href = window.URL.createObjectURL(data)
                  link.download =
                    `${'Inform-consent'}-${moment().format("YYYY-MM-DD")}-${this.rowReg.aur_reg_code}.pdf`
                  link.click()
                },
                fail: data => {
                  self.loadingOverlay = false
                  alert('Not downloaded')
                }
              })
            }, 2000)
          }
        })
      },
    },
    watch: {
      $route() {
        this.apiGet()
      },
    }
  }
</script>